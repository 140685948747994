
import {Component, Prop, Vue} from 'vue-property-decorator';
import stores from '@/stores';
import {SweetModal} from 'sweet-modal-vue';
import GlobalFunctions from '@/GlobalFunctions';

@Component({
  components: {
    SweetModal,
  },
})
export default class CreateInterests extends Vue {
  @Prop({default: false})
  emitCreated!: boolean;

  @Prop()
  parentId!: number | string;

  interests: any = {
    name : '',
    parentId: '',
  };

  globalFunctions = new GlobalFunctions();

  form!: HTMLFormElement;

  mounted() {
    this.form = document.getElementById('create-form-interests')! as HTMLFormElement;
    // listen for escape button click
    document.addEventListener('keydown', this.keyDown);
    // listen for submit and validate form
    this.form.addEventListener('submit', (event: any) => {
      event.preventDefault();
      if (this.form.checkValidity() === true) {
        event.stopPropagation();
        this.onSubmit();
      }
      if (this.form.checkValidity() === false) {
        event.stopPropagation();
      }
      this.form.classList.add('was-validated');
    }, false);

  }

  beforeDestroy() {
    document.removeEventListener('keydown', this.keyDown);
  }

  onSubmit() {
    this.interests.parentId = this.parentId;
    return stores.Interests.createNewItem(this.interests)
      .then((response) => {
        console.log(response);
        this.cancel();
        this.$emit('created');
      })
      .catch((e) => {
        console.log(e);
      });
  }

  keyDown(event: any) {
    if (event.key === 'Escape') {
      this.cancel();
    }
  }

  cancel() {
    // @ts-ignore
    this.$children[0].close(); // close modal
    this.form.classList.remove('was-validated'); // remove validation styles
  }
}
