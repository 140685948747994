
import { Component, Prop, Vue } from 'vue-property-decorator';
import stores from '../../stores';
import axiosInstance from '../../helpers/axios';

@Component
export default class LostPassword extends Vue {
  session = stores.session;
  email: string = '';
  error: boolean = false;
  errorMessage: string = '';
  admin = stores.admin;
  image = require('@/assets/images/profile-img.png');
  step: number = 1;

  onSubmit(): Promise<boolean> {
    this.error = false;
    return new Promise((resolve, reject) => {
      axiosInstance.patch('/admins/password/reset', {projectId: stores.session.projectId, email: this.email})
      .then((response) => {
        if (response.status === 200) {
          this.step = 2;
          this.email = '';
        } else {
          this.error = true;
          this.errorMessage = response.data.errors[0].message;
        }
      })
      .catch((error) => {
        this.error = true;
        this.errorMessage = error.response.data.message;
      });
    });
  }
}
