
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {SweetModal} from 'sweet-modal-vue';
import stores from "@/stores";
import {showToaster} from "@/components/DashboardComposer/helpers";
import TimeZones from "@/modules/TimeZones.vue";

@Component({
  components: {TimeZones, SweetModal}
})
export default class SuperAdminDetails extends Vue {
  @Prop({ required: true }) userData!: ISuperAdmin;

  currentPassword: string = '';
  adminProfile: ISuperAdmin | null = null;

  @Watch('userData', { immediate: true })
  onProfileToEditChange(newValue: ISuperAdmin, oldValue: ISuperAdmin) {
    this.adminProfile = newValue;
  }

  get adminProfileInitials() {
    if (!this.adminProfile || !this.adminProfile.name) return '';
    const initials = this.adminProfile.name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  }

  get currentTime() {
    return new Date();
  }

  async onSubmit()  {
    if (!this.adminProfile || !this.validateForm() || !this.validatePassword()) {
      return Promise.resolve();
    }

    const isPasswordValid = await this.validatePassword();
    if (!isPasswordValid) {
      // Password is not valid, do not close the modal
      return;
    }

    return stores.SuperAdmins.updateItem(this.adminProfile)
      .then((response: any) => {
        // @ts-ignore
        showToaster('success', '', 'User successfully updated.');
        this.closeModal();
      })
      .catch((e: any) => {
        console.log({e});
      });
  }

  formatTime(time: Date, format: string): string {
    const hours = time.getHours();
    const minutes = time.getMinutes();

    const formattedHours = format === '12' ? (hours % 12 || 12) : hours;
    const formattedMinutes = String(minutes).padStart(2, '0');

    if (format === '24') {
      return `${formattedHours}:${formattedMinutes}`;
    } else if (format === '12') {
      const ampm = hours >= 12 ? 'PM' : 'AM';
      return `${formattedHours}:${formattedMinutes} ${ampm}`;
    } else {
      return '';
    }
  }
  formatDate(date: Date, format: string): string {
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const formattedDay = String(day).padStart(2, '0');
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const monthName = monthNames[monthIndex];

    if (format === 'dmy') {
      return `${formattedDay} ${monthName}, ${year}`;
    } else if (format === 'mdy') {
      return `${monthName} ${formattedDay}, ${year}`;
    }
    return '';
  }

  closeModal() {
    // @ts-ignore
    this.$children[0].close();
    this.clearValidation();
  }

  validateForm(): boolean {
    const form = this.$refs['updateForm'] as HTMLFormElement;
    form.classList.add('was-validated');
    return form.checkValidity() && !this.isInvalidConfirmation;

  }

  timeZoneSelected(zone: string) {
    if (this.adminProfile) {
      this.adminProfile.timeZone = zone;
    }
  }
  get isInvalidConfirmation() {
    if (this.adminProfile) {
      if (!this.adminProfile.password)
        return false;
      return this.adminProfile.password !== this.adminProfile.passwordConfirmation;
    }
  }

  async validatePassword(): Promise<boolean> {
    if (this.adminProfile) {
      try {
        const passwordCheck = {
          'password': this.currentPassword ?? false,
          'passwordConfirmation': !!this.adminProfile.password
        };
        const inputField = this.$refs['currentPassword'] as HTMLInputElement;

        const isValid = await stores.SuperAdmins.checkCurrentPassword(this.adminProfile.id, passwordCheck);
        if (!isValid) {
          inputField.classList.add('is-invalid');
        } else {
          inputField.classList.remove('is-invalid');
          this.currentPassword = '';
        }

        return isValid;
      } catch (error) {
        console.error(error);
        return false;
      }
    }
    return true;
  }


  isPasswordValid() {
    return this.validatePassword();
  }

  clearValidation() {

    if(!this.adminProfile) {
      return;
    }

    const form = this.$refs['updateForm'] as HTMLFormElement;
    form.classList.remove('was-validated');
    const inputField = this.$refs['currentPassword'] as HTMLInputElement;
    inputField.classList.remove('is-invalid');
    this.adminProfile.password = this.adminProfile.passwordConfirmation = '';
  }


}
