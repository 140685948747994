
import {Component, Vue} from 'vue-property-decorator';
import stores from '@/stores';
import NevronHeader from '@/components/NevronHeader.vue';
import {hasPermissionForRoleByType} from "@/helpers/permissions";
import {showToaster} from "@/components/DashboardComposer/helpers";
import Multiselect from 'vue-multiselect'

interface Role {
  createdAt: string;
  guardName: string;
  id: number;
  name: string;
  updatedAt: string;
}

@Component({
  methods: {hasPermissionForRoleByType},
  components: {
    NevronHeader,
    Multiselect
  },
})

export default class CreateAdmin extends Vue {
  admin: any = {
    id: null,
    email: null,
    name: '',
    username: null,
    roles: [],
    password: '',
    passwordConfirmation: '',
    status: 1,
  };
  error: string = '';
  roles: [] = [];
  availableRoles: [] = [];

  nameRules = {};
  emailRules = {};
  usernameRules = {};
  rolesRules = {};
  passwordRules = {};
  statusRules = {};

  sortScopedRoles() {
    this.availableRoles.sort((a: Role, b: Role) => {
        if (a.name === 'User' || a.name === 'Admin') {
          return 1; // Move 'User' and 'Admin' roles to the end
        } else if (b.name === 'User' || b.name === 'Admin') {
          return -1; // Keep 'User' and 'Admin' roles in their current order
        }
        return 0; // Maintain the order of other roles
      });
  }
  getScopedRoles()
  {
      return this.availableRoles
  }

  onSubmit() {
    this.error = '';
    stores.admins.create(this.admin)
      .then((response) => {
        if (response.data && response.responseCode && (response.responseCode === 200 || response.responseCode === 201) ) {
          showToaster('success', Vue.prototype.translate(this.admin.name), 'successfully created');
          this.$router.push({name: 'access.roles'});
          return
        }
        let error = response;
        if (error.status === 422) {
          let msg = error.data.errors
          if(error.data.errors.name)
          {
            msg = error.data.errors.name
          }
          else if(error.data.errors.email)
          {
            msg = error.data.errors.email
          }
          else if(error.data.errors.username)
          {
            msg = error.data.errors.username
          }
          showToaster('danger', '', msg , error.status);
          return
        }
        if (error.status >= 400 && error.status < 500) {
          showToaster('danger', '', error.data.error.message , error.status);
        }
      }).catch((error) => {
      showToaster('danger', Vue.prototype.translate(this.admin.name), 'Failed to create');
    });
   }

  mounted() {

    stores.admins.getAdminValidationRules()
    .then((response) => {
      const validationRules = response;
      if (validationRules[`name`]) {
        this.nameRules = validationRules[`name`];
      }
      if (validationRules[`email`]) {
        this.emailRules = validationRules[`email`];
      }
      if (validationRules[`username`]) {
        this.usernameRules = validationRules[`username`];
      }
      if (validationRules[`password`]) {
        this.passwordRules = validationRules[`password`];
      }
      if (validationRules[`status`]) {
        this.statusRules = validationRules[`status`];
      }
      if (validationRules['roles']) {
        this.rolesRules = validationRules['roles'];
      }
    });

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.getElementsByClassName('needs-validation');

    stores.roles.getAllRoles().then((response) => {
      this.availableRoles = response;
      this.sortScopedRoles();
    });

    // Loop over them and prevent submission
    const validation = Array.prototype.filter.call(forms, (form) => {
      form.addEventListener('submit', (event: any) => {
        event.preventDefault();
        if (form.checkValidity() === true) {
          event.stopPropagation();
          this.onSubmit();
        }
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });
  }
}
