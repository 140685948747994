
import {Component, Vue, Watch} from 'vue-property-decorator';
import stores from '@/stores';
import vPagination from '@/components/VuePlainPagination.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import router from '@/router';
import NevronImageField from '@/components/NevronImageField.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';
import Skeleton from '@/modules/Skeleton.vue';
import ChangePassword from './ChangePassword.vue';
import AddUserStay from './../Stay/Create.vue';
import {translate} from '@/helpers/functions';

@Component({
  components: {
    NevronImageField,
    NevronHeader,
    NevronFooter,
    vPagination,
    Skeleton,
    ChangePassword,
    AddUserStay,
  },
})

export default class CombinedUserShow extends Vue {
  selectedUser: any | null = null;
  stores = stores; // bring stores in template context
  indexList: number[] = [];
  allChecked: boolean = false;

  @Watch('$route', {immediate: true, deep: true})
  changePage() {
    this.refresh(Number(this.$route.params.id));
  }

  refresh(id: number) {
    return stores.CombinedUsers.getUser(id)
      .then((response: any) => {
        this.selectedUser = response.data;
        console.log(this.selectedUser);
      })
      .catch((e: any) => {
        console.log(e);
      });
  }

  deleteUser() {
    // @ts-ignore
    stores.CombinedUsers.deleteItem(this.selectedUser.id)
      .then((response: any) => {
        if (!response.success) {
          throw new Error(response.error.message);
        }
        // @ts-ignore
        showToaster('success', translate('successfully-deleted'), this.selectedUser.name);
        // this.$router.push({
        //   name: 'customers',
        //   query: {tab: 'combined-users'},
        // });

        this.$router.push({path: '/guests', query: {tab: 'combined-users'}});

      })
      .catch((error) => {
        showToaster('danger', '', error);
      });
  }

  onSubmitPassword(user: ICombinedUser) {
    if (this.selectedUser) {
      return stores.CombinedUsers.updateItem(this.selectedUser.id, {password: user.password})
        .then((response) => {
          if (!response.success) {
            showToaster('success', '', response.error.message);
          } else {
            this.refresh(Number(this.$route.params.id));
            showToaster('success', '', translate('password-change'));
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  save() {
    // Create FormData object
    const formData = new FormData();
    // Append file to form data
    formData.append('photo', this.selectedUser.photo);
    // Loop through the object and append attributes to form data
    for (const key in this.selectedUser) {
      if (this.selectedUser.hasOwnProperty(key)) {
        formData.append(key, this.selectedUser[key]);
      }
    }

    return stores.CombinedUsers.updateItem(this.selectedUser.id, formData)
      .then(async (response: any) => {
        if (!response.success) {
          // Loop through the errors object and log each message
          for (const field in response.errors) {
            if (response.errors.hasOwnProperty(field)) {
              const messages = response.errors[field];
              messages.forEach((message: any) => {
                showToaster('danger', '', message);
              });
            }
          }
        } else {
          await this.refresh(Number(this.$route.params.id));
          showToaster('success', this.selectedUser?.username, 'successfully updated');
        }
      });
  }

  onSubmitStatus() {
    if (this.selectedUser) {
      return stores.CombinedUsers.updateItem(this.selectedUser.id, {active: this.selectedUser.active})
        .then(() => {
          console.log('success');
        }).catch((e: any) => {
          console.log(e);
        });
    }
  }

  profileSelected(e: any) {
    const file = e.target.files[0];
    this.selectedUser.photo = file;

    const formdata = new FormData();
    formdata.append('photo', file);

    return stores.CombinedUsers.updateProfile(this.selectedUser.id, formdata)
      .then((response: any) => {
        if (!response.success) {
          if (response.responseCode === 422) {
            // Loop through the errors object and log each message
            for (const field in response.errors) {
              if (response.errors.hasOwnProperty(field)) {
                const messages = response.errors[field];
                messages.forEach((message: any) => {
                  showToaster('danger', '', message);
                });
              }
            }
          } else {
            showToaster('danger', '', response.error.message);
          }
        } else {
          this.refresh(this.selectedUser.id);
          showToaster('success', '', translate('successfully-updated'));
        }
        console.log(response);
      });
  }


  // stays management area
  updateCheck() {
    if (this.selectedUser && this.indexList.length === this.selectedUser.stays.length) {
      this.allChecked = true;
    } else {
      this.allChecked = false;
    }
  }

  checkAll() {
    if (!this.allChecked && this.selectedUser) {
      this.indexList = [];
      // @ts-ignore
      for (const iterator of this.selectedUser.stays) {
        if (iterator.id) {
          this.indexList.push(iterator.id);
        }
      }
      this.allChecked = true;
    } else {
      this.indexList = [];
      this.allChecked = false;
    }
  }

  detachStay(stayIds: []) {
    stores.CombinedUsers.removeStays(this.selectedUser.id, stayIds)
      .then((response: any) => {
        if (!response.success) {
          showToaster('danger', '', translate(response.error.message));
        } else {
          showToaster('success', translate('stay-detached-successfully'), '');
          this.refresh(this.selectedUser.id);
        }
      })
  }


}
