
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {mixin as Clickaway} from 'vue-clickaway';
import {SweetModal} from 'sweet-modal-vue';
import NevronInput from '../../../components/NevronInput.vue';
import TvModule from '@/stores/modules/TvModule';

@Component({
  components: {
    SweetModal,
    NevronInput,
  },
  mixins: [Clickaway],

})
export default class ChannelTimeshifts extends Vue {
  @Prop() timeshift!: ITimeShift | null;
  @Prop() channel!: IChannel;
  @Prop() allTypes!: any;
  @Prop()
  module!: TvModule;

  newTimeshift: ITimeShift | null = null;
  alltyps: any = null;

  openTranslator(localText: any, localKey: any, referenceKey: any) {
    // @ts-ignore
    this.$bus.$emit('openTranslator', localText, localKey, referenceKey);
  }

  @Watch('timeshift', {immediate: true})
  setModuleFromProps() {

    if (this.timeshift) {
      this.newTimeshift = JSON.parse(JSON.stringify(this.timeshift));
    } else {
      this.newTimeshift = {
        id: null,
        tvChannelId: -1,
        priority: null,
        description: '',
        recorderId: 0,
        source: '',
        recordingTime: -1,
        deviceTypeId: 0,
        quality: 'SD',
      };
    }
  }

  @Watch('channel')
  channelId() {
    if (this.newTimeshift && this.channel) {
      this.newTimeshift.tvChannelId = Number(this.channel.id);
      this.newTimeshift.recordingTime = (this.channel.timeShiftDays !== null ? this.channel.timeShiftDays : 0);
    }
  }

  @Watch('allTypes')
  setTypes() {
    this.alltyps = JSON.parse(JSON.stringify(this.allTypes));
  }

  save(): void {
    if (this.newTimeshift) {
      if (this.timeshift === null) {
        this.$emit('saveNew', this.newTimeshift);
      } else {
        this.$emit('saveEdit', this.newTimeshift);
      }
      // @ts-ignore
      // this.$children[0].close();
    }
  }

  cancel(): void {
    this.$emit('cancel');
    // @ts-ignore
    this.$children[0].close();
  }
}
