
import {Component, Vue, Watch} from 'vue-property-decorator';
import stores from '@/stores';
import Axios from 'axios';
import editPassword from '@/modules/Users/Edit.vue';
// @ts-ignore
import AttachDashboard from '@/modules/Dashboards/Attach.vue';
import AttachMobileDashboard from '@/modules/Dashboards/AttachMobile.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronInput from '@/components/NevronInput.vue';
import TableModalGeneric from '@/components/NevronTableModalGeneric.vue';
import CreateCustomer from '@/modules/Customers/Create.vue';
import TableGeneric from '@/components/NevronTableGeneric.vue';
import ResetPin from '@/modules/Accounts/Profiles/ResetPin.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';

@Component({
  components: {
    editPassword,
    AttachDashboard,
    AttachMobileDashboard,
    NevronHeader,
    NevronFooter,
    Skeleton,
    NevronInput,
    CreateCustomer,
    TableModalGeneric,
    TableGeneric,
    ResetPin,
  },
})

export default class UserDetails extends Vue {
  response: any = null;
  selectedUser!: IUser;
  showConfirm: boolean = false;
  tempAcc!: IAccount;
  devices: IDevice[] = [];
  searchQuery: string = '';
  indexList: number[] = [];
  allChecked: boolean = false;
  attachVisible: boolean = false;
  fullResponse: any = null;
  fullDashboardResponse: any = null;
  searchInProgress: boolean = false;
  CancelToken: any = Axios.CancelToken;
  source: any;
  searchKeyword: string = '';
  types: string = 'stb';
  indexDashboard: any = '';
  customerName: any = '';

  notification: any = {
    message: '',
    result: '',
    isAvailable: false,
  };
  stores = stores;
  defaultUserId: any = null;

  get selectedDashboard() {
    return (this.selectedUser.dashboard) ? [this.selectedUser.dashboard] : [];
  }

  get selectedMobileDashboard() {
    return (this.selectedUser.mobileDashboard) ? [this.selectedUser.mobileDashboard] : [];
  }

  mounted() {
    this.refresh(Number(this.$route.params.id));
  }

  /**
   *
   */
  @Watch('$route', {immediate: true, deep: true})
  changePage() {
    this.refresh(Number(this.$route.params.id));
  }

  onSubmit() {
    // @ts-ignore
    if (this.selectedUser.locked === 1 || this.selectedUser.locked === '1' || this.selectedUser.locked) {
      showToaster('danger', ' ', 'The content is locked by the CMS integration module!', 500);
    } else {
      if (this.selectedUser) {
        // @ts-ignore
        if (this.selectedUser.customer) {
          this.selectedUser.name = Vue.prototype.translate(this.selectedUser.customer.firstName) + ' ' + Vue.prototype.translate(this.selectedUser.customer.lastName);
        }
        if (this.selectedUser.default) {
          this.defaultUserId = this.selectedUser.id;
        }
        stores.Setting.setUser(this.defaultUserId);

        console.log('Updating user: ', this.selectedUser);
        return stores.User.updateItem(this.selectedUser.id, this.selectedUser)
          .then(async (response) => {
            console.log(response.responseCode)
            if (response.responseCode === 200) {
              await this.refresh(Number(this.$route.params.id));
              showToaster('success', this.selectedUser.username, 'successfully updated');
            } else if (response.responseCode === 400) {
              // Handle email conflict error
              showToaster('warning', this.selectedUser.username, 'Fail to update: ' + response.message, 400);
            } else {
              showToaster('danger', this.selectedUser.username, 'Fail to update', response.responseCode);
            }
          })
          .catch((e) => {
            console.log(e);
            showToaster('danger', this.selectedUser.username, 'Fail to update');
          });
      }
    }
  }

  deleteUser() {
    // @ts-ignore
    if (this.selectedUser.locked === 1 || this.selectedUser.locked === '1' || this.selectedUser.locked) {
      showToaster('danger', ' ', 'The content is locked by the CMS integration module!', 500);
      // @ts-ignore
    } else if (this.selectedUser.default === 1 || this.selectedUser.default === '1' || this.selectedUser.default) {
      showToaster('danger', ' ', 'Cannot delete default account' + ' ' + this.selectedUser.username, 500);
    } else {
      if (this.selectedUser && this.selectedUser.id) {
        return stores.User.deleteItem(this.selectedUser.id)
          .then((response) => {
            console.log(response);
            // @ts-ignore
            this.$refs.edit.$children[0].close();
            this.$router.push({name: 'users'});
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  }

  onSubmitPassword(user: IUser) {
    if (this.selectedUser) {
      return stores.User.updateItem(this.selectedUser.id, {password: user.password})
        .then((response) => {
          this.refresh(Number(this.$route.params.id));
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  savePin(newPin: number) {
    this.selectedUser.pin = newPin;
  }

  clearNotification() {
    this.notification = {
      message: '',
      result: false,
      isAvailable: false,
    };
  }

  refresh(id: number) {
    return stores.User.getSelectedUser(id)
      .then((response) => {
        this.response = response;
        this.selectedUser = response.data;
        this.customerName = '';
        // @ts-ignore
        if (this.selectedUser.customer) {
          // @ts-ignore
          this.customerName = Vue.prototype.translate(this.selectedUser.customer.firstName) + ' ' + Vue.prototype.translate(this.selectedUser.customer.lastName);
        }
        console.log('customer', this.selectedUser.customer);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  attachCustomer(customer: ICustomer) {
    this.selectedUser.customer = customer;
    // @ts-ignore
    this.selectedUser.customerId = customer.id;
  }

  detachCustomer() {
    this.selectedUser.customer = null;
    // @ts-ignore
    this.selectedUser.customerId = null;
  }

  attachAccounts(accounts: IAccount[]) {
    const newAccounts = accounts.filter((acc: IAccount) => !this.accountIds.includes(acc.id));
    this.selectedUser.accounts = this.selectedUser.accounts.concat(newAccounts);
  }

  get accountIds() {
    return this.selectedUser.accounts.map((acc: IAccount) => acc.id);
  }

  detachAccount(account: IAccount) {
    this.selectedUser.accounts = this.selectedUser.accounts.filter((acc: IAccount) => acc.id !== account.id);
  }

  // STB dashboards
  attachDashboard(dashboard: IDashboard) {
    this.selectedUser.dashboard = dashboard;
    this.selectedUser.dashboardId = dashboard.id!;
  }

  // Mobile dashboards
  attachMobileDashboard(dashboard: IDashboard) {
    this.selectedUser.mobileDashboard = dashboard;
    this.selectedUser.mobileDashboardId = dashboard.id!;
  }

}
