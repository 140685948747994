
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import globalConfig from "../../stores/GlobalConfig";
import stores from "@/stores";
import {FormWizard, TabContent} from "vue-form-wizard";
import {excludedFeaturesForProjectType} from "./ProjectManagementConfig";
import {showToaster} from '@/components/DashboardComposer/helpers';

@Component({
  components: {FormWizard, TabContent}
})
export default class ProjectManagement extends Vue {

  @Prop({default: false}) onlyWizard!: boolean; // Only body without header and footer
  @Prop({default: null}) allowedFeatures!: string[] | null;
  @Prop({default: null}) operator!: IOperator | null;
  @Prop({default: undefined}) projectId!: string | null | undefined;

  config = globalConfig.config;

  project = {
    id: '',
    name: '',
    display_name: '',
    operator: {
      id: this.operator?.id,
      name: this.operator?.name,
    },
    type: null as IProjectType | null,
    settings: [] as ISetting[],
    isModified: true
  };

  error = false;
  errorMessage = '';
  loading = false;
  isEditMode = false;
  isCopyMode = false;

  isDuplicateName = false;
  isNameRequired = false;
  originalProjectName = '';

  logo: ISetting = {
    id: 0,
    value: '',
    key: '',
  };

  allSettings: ISetting[] = [];
  featuresList: IFeature[] = [];
  projectTypesList: IProjectType[] = [];
  projectTypesTranslations: any = null;
  selectedProjectGroup = '';

  catalogueLimit: any = null;

  get groupedFeatures() {
    const groups: { [key: string]: IFeature[] } = {};
    this.featuresList.forEach((feature) => {
      if(this.allowedFeatures && !this.allowedFeatures.includes(feature.permission)) {
        return;
      }
      if (!groups[feature.group]) {
        groups[feature.group] = [];
      }
      groups[feature.group].push(feature);
    });
    return groups;
  }

  get groupedProjectTypes() {
    const groups: { [key: string]: IProjectType[] } = {};
    this.projectTypesList.forEach((projectType) => {
      if (!groups[projectType.group]) {
        groups[projectType.group] = [];
      }
      groups[projectType.group].push(projectType);
    });
    return groups;
  }

  get showPayment() {
    if (this.getSettingValue('module-type.CATALOGUE.reservations') || this.getSettingValue('module-type.CATALOGUE.orders')) {
      return true;
    }
    return false;

  }

  @Watch('project.type')
  onProjectTypeChange(newType: IProjectType, oldType: IProjectType) {
    if (!newType) {
      return;
    }
    this.updateSettingValue('project_type', newType.key);
    if (this.isEditMode) {
      return;
    }
    this.prepareCheckboxesForProjectType(newType.key);
    this.$forceUpdate();
  }

  @Watch('operator')
  onOperatorChange({ id, name }: IOperator) {
    this.project.operator = { id, name };
  }

  @Watch('projectId')
  onProjectIdChange() {
    this.init();
  }

  placeholderText(key: string) {
    if(key === 'feature.STB_DASHBOARDS' || key === 'feature.MOBILE_DASHBOARDS') {
      return 'Number of sections limit';
    }
    return 'Number of digital services limit';
  }
  validateForm(formRef: string): boolean {
    const form = this.$refs[formRef] as HTMLFormElement;
    form.classList.add('was-validated');
    return form.checkValidity();
  }

  mounted() {
    this.init();
  }

  async init() {
    if (this.getProjectId()) {
      this.$route.name === 'projects.copy' ?
        this.isCopyMode = true :
        this.isEditMode = true;
      await this.fetchData(this.getProjectId() || '');
    }

    await stores.master.getFeatures();
    this.projectTypesTranslations = await stores.master.getProjectTypesTranslations() || [];
    this.featuresList = (stores.master.features || [])
      .filter(item => item.permission !== "module-type.CATALOGUE.item");

    // Filter out beta features
    if (!stores.globalConfig.config.showBetaFeatures) {
      this.featuresList = (stores.master.features || [])
        .filter(item => item.permission !== "feature.CHAT" && item.permission !== "feature.WELCOME_SCREEN");
    }
    this.prepareSettings();

    this.projectTypesList = await stores.master.getProjectTypes() || [];
    if (this.project.type) {
      this.selectedProjectGroup = this.project.type.group || '';
    }

    if (!this.project.isModified && !window.location.href.endsWith('copy')) {
      showToaster('danger', ' ', 'you can not edit this tenant', 500);
      this.$router.push({name: 'projects'});
    }
  }

  getProjectId(): string | null {
    return (this.projectId === undefined)
      ? this.$route.params.id
      : this.projectId;
  }

  // Modify the fetchData method in the <script> section
  fetchData(id: string) {
    return stores.master.getSpecificProject(id)
      .then((response: any) => {
        this.project = response.data;
        this.originalProjectName = response.data.name;
        this.project.display_name = response.data.displayName;
        this.project.id = id;
        this.project.operator = {id: this.operator?.id, name: this.operator?.name};
        this.project.isModified = response.data.isModified;
        this.project.settings = JSON.parse(response.data.settings);
        // loading catalogue additional
        const orders = this.project.settings.find(item => item.key === 'module-type.CATALOGUE.orders');
        this.updateSettingValue('module-type.CATALOGUE.orders', orders ? orders.value : false);
        const reservations = this.project.settings.find(item => item.key === 'module-type.CATALOGUE.reservations');
        this.updateSettingValue('module-type.CATALOGUE.reservations', reservations ? reservations.value : false);
        const payment = this.project.settings.find(item => item.key === 'feature.SETTINGS.online-payments');
        this.updateSettingValue('feature.SETTINGS.online-payments', payment ? payment.value : false);
        const catalogueItem = this.project.settings.find(item => item.key === 'module-type.CATALOGUE.item.limit');
        if (catalogueItem && catalogueItem.value) {
          this.catalogueLimit = catalogueItem.value;
        }

        if (!stores.globalConfig.config.showBetaFeatures) {
          this.updateSettingValue('feature.CHAT', false);
          this.updateSettingValue('feature.WELCOME_SCREEN', false);
        }
      })
      .then((response: any) => {
        if (this.$route.name === 'projects.copy') {
          this.project.name += " copy";
        }
      })
      .catch(() => {
      });
  }


  translateForProjectType(str: string): string {
    if (!this.project.type?.key) {
      return str;
    }
    const translations = this.projectTypesTranslations[this.project.type.key]['en_UK'];
    const strKey = Vue.prototype.makeKey(str);
    const translation = translations.find((item: any) => strKey == item.key);
    if (!translation) {
      return str;
    }
    return translation.value;
  }

  prepareSettings() {
    this.updateSettingValue('module-type.CATALOGUE.orders', false);
    this.updateSettingValue('module-type.CATALOGUE.reservations', false);
    this.updateSettingValue('feature.SETTINGS.online-payments', false);

    for (const feature of this.featuresList) {
      this.allSettings.push({
        key: feature.permission,
        value: undefined,
      });
      if (feature.settingKey) {
        this.allSettings.push({
          key: feature.settingKey,
          value: undefined,
        });
      }
    }
    for (const setting of this.project.settings) {
      this.updateSettingValue(setting.key, setting.value);
    }
  }

  prepareCheckboxesForProjectType(projectTypeKey: string) {
    if (!projectTypeKey) {
      return;
    }
    this.setValueToAllFeatures(true);
    const featuresToExclude = excludedFeaturesForProjectType[projectTypeKey] || [];
    for (const setting of this.allSettings) {
      if (featuresToExclude.includes(setting.key)) {
        setting.value = false;
      }
    }
  }

  allChecked() {
    for (const setting of this.allSettings) {
      if (setting.value === false || setting.value === undefined) {
        if (!setting.key.includes('.limit')) {
          return false;
        }
      }
    }

    return true;
  }

  allCheckedInGroup(group: any) {
    for (const feature of group) {
      if (this.getSettingValue(feature.permission) === false || this.getSettingValue(feature.permission) === undefined) {
        return false;
      }
    }

    return true;
  }

  setValueToAllFeatures(value: boolean) {
    for (let index = 0; index < this.allSettings.length; index++) {
      const setting = this.allSettings[index];
      if (setting.key.includes('.limit')) {
        continue;
      }
      setting.value = value;
    }
  }

  setValueToAllFeaturesInGroup(group: any, value: boolean) {
    for (const feature of group) {
      this.updateSettingValue(feature.permission, value);
    }
  }

  getSettingValue(settingKey: string): any {
    const setting = this.allSettings.find((setting: ISetting) => setting.key == settingKey);
    return setting?.value;
  }

  updateSettingValue(settingKey: string, value: any) {
    const index = this.allSettings.findIndex((setting: ISetting) => setting.key == settingKey);
    // If setting doesn't exist, create it
    if (index === -1) {
      this.allSettings.push({key: settingKey, value: value});
      return;
    }
    this.allSettings[index].value = value;
  }

  onSubmit() {
    if (this.isCopyMode && this.project.name === this.originalProjectName) {
      this.isDuplicateName = true;
    } else {
      this.isDuplicateName = false;
    }

    if (!this.validateForm('formStep1') || this.isDuplicateName) {
      return;
    }

    this.loading = true;
    this.updateSettingValue('project_type', this.project.type?.key);
    this.updateSettingValue('module-type.CATALOGUE.item.limit', this.catalogueLimit);
    if (!this.showPayment) {
      this.updateSettingValue('feature.SETTINGS.online-payments', false);
    }

    this.project.settings = this.allSettings.filter(setting => setting.value);

    let projectAction: Promise<any>;
    let successRedirect: string;

    if (this.isCopyMode && !this.isEditMode) {
      projectAction = stores.master.copyProject(this.project.id, this.project);
      successRedirect = '/admin/projects/finish/duplicated';
    } else if (this.isEditMode && !this.isCopyMode) {
      projectAction = stores.master.updateProject(this.project.id, this.project);
      successRedirect = '/admin/projects/finish/edited';
    } else {
      projectAction = stores.master.createProject(this.project);
      successRedirect = '/admin/projects/finish/created';
    }

    projectAction.then((response) => {
      if (response.error) {
        this.error = true;
        this.errorMessage = response.error.message;
        this.loading = false;
      } else {
        window.location.href = `${window.location.origin}${successRedirect}`;
      }
    })
      .catch((error) => {
        this.error = true;
        this.errorMessage = error.response && error.response.data ? error.response.data.message : error;
        this.loading = false;
      });
  }


  @Watch('project.name')
  onProjectNameChange(newName: string) {
    this.validateProjectName(newName);
  }

  validateProjectName(name: string) {
    this.isDuplicateName = this.isCopyMode && name === this.originalProjectName;
    this.isNameRequired = !name;
  }

  get projectNameInvalid() {
    return this.projectNameRequired || !this.projectNamePatternValid || this.isDuplicateName;
  }

  get projectNameRequired() {
    return this.project.name.trim() === '';
  }

  get projectNamePatternValid() {
    const pattern = new RegExp('^[a-zA-Z0-9 ]*$');
    return pattern.test(this.project.name);
  }
}
