import Account from '@/stores/Account.ts';
import AdminCollection from '@/stores/Admins';
import BulkEdit from '@/stores/BulkEdit.ts';
import Customer from '@/stores/Customer.ts';
import Devices from '@/stores/Devices.ts';
import DeviceType from '@/stores/DeviceType.ts';
import DeviceGroups from '@/stores/DeviceGroup.ts';
import EPG from '@/stores/EPG.ts';
import File from '@/stores/File.ts';
import Folder from '@/stores/Folder.ts';
import GlobalConfig from '@/stores/GlobalConfig.ts';
import Location from '@/stores/Location.ts';
import ModulesCollection from './ModulesCollection';
import Package from '@/stores/Package.ts';
import PackageItems from '@/stores/PackageItem.ts';
import Session from '@/stores/Session';
import Master from '@/stores/Master';
import TheAdmin from '@/stores/TheLogedInAdmin';
import User from '@/stores/User.ts';
import Dashboards from '@/stores/Dashboard';
import MailConfiguration from '@/stores/MailConfiguration';
import Setting from '@/stores/Setting.ts';
import Profile from '@/stores/Profile.ts';
import MobileComposer from '@/stores/composer/Mobile';
import STBComposer from '@/stores/composer/STB';
import Matomo from '@/stores/Matomo';
import Language from '@/stores/Language.ts';
import Translation from '@/stores/Translation.ts';
import Stays from '@/stores/Stays.ts';
import Promotions from '@/stores/Promotions';
import Interests from '@/stores/Interests';
import Assets from '@/stores/assets';
import Rooms from '@/stores/Rooms';
import Library from '@/stores/Library';
import Messaging from '@/stores/Messaging';
import RSS from '@/stores/RSS';
import Media from '@/stores/Media';
import GuestFlow from '@/stores/GuestFlow';
import StayTypes from '@/stores/StayTypes';
import AssetCategory from '@/stores/AssetCategory';
import ContactModule from '@/stores/ContactModule';
import RoomType from '@/stores/RoomType';
import SocialMedia from '@/stores/SocialMedia';
import CommentProposal from '@/stores/CommentProposal';
import RatingPlatform from '@/stores/RatingPlatform';
import GFMobileComposer from '@/stores/composer/GFMobile';
import Accessories from '@/stores/Accessories';
import RoleCollection from '@/stores/Roles';
import FeatureCollection from '@/stores/Features';
import DigitalSignage from '@/stores/modules/DigitalSignage';
import Surveys from '@/stores/Surveys';
import WeatherLocationModule from './modules/WeatherLocationModule';
import WelcomeScreenCollection from "@/stores/modules/WelcomeScreen";
import ChatContact from '@/stores/chat/ChatContact';
import Orders from './Orders';
import Reservations from './Reservations';
import Roomcare from './Roomcare';
import CombinedUsers from '@/stores/CombinedUser';
import SuperAdminDevices from '@/stores/SuperAdminDevices';
import SuperAdmins from '@/stores/SuperAdmins';
import SuperAdminOperators from "@/stores/SuperAdminOperators";
import Moods from '@/stores/Moods';
import CurrencyExchangeCollection from "@/stores/CurrencyExchange";

import InstructionCategories from "@/stores/Instructions";
import ChatMessageCollection from '@/stores/chat/ChatMessage';
import MessageCollection from './MessageLog';


const stores = {
  epg: new EPG(),
  profile: new Profile(),
  accounts: new Account(),
  BulkEdit: new BulkEdit(),
  Customer: new Customer(),
  Devices: new Devices(),
  SuperAdminDevices: new SuperAdminDevices(),
  SuperAdminOperators: new SuperAdminOperators(),
  SuperAdmins: new SuperAdmins(),
  DeviceType: new DeviceType(),
  DeviceGroups: new DeviceGroups(),
  Setting: new Setting(),
  globalConfig: GlobalConfig,
  Location: new Location(),
  Language: new Language(),
  Translation: new Translation(),
  admin: new TheAdmin(), // Logged in admin
  admins: new AdminCollection(),
  modules: new ModulesCollection(),
  session: Session, // Local session data
  master: new Master(),
  User: new User(),
  File: new File(),
  Folder: new Folder(),
  Package: new Package(),
  PackageItems: new PackageItems(),
  dashboards: new Dashboards(),
  mobileComposer: new MobileComposer(),
  STBComposer: new STBComposer(),
  Matomo: new Matomo(),
  Stays: new Stays(),
  StayTypes: new StayTypes(),
  Promotions: new Promotions(),
  Interests: new Interests(),
  Assets: new Assets(),
  AssetCategory: new AssetCategory(),
  ContactModule: new ContactModule(),
  Rooms: new Rooms(),
  Messaging: new Messaging(),
  Surveys: new Surveys(),
  RoomType: new RoomType(),
  Library: new Library(),
  Media: new Media(),
  SocialMedia: new SocialMedia(),
  CommentProposal: new CommentProposal(),
  RatingPlatfrom: new RatingPlatform(),
  GuestFlow: new GuestFlow(),
  gFMobileComposer: new GFMobileComposer(),
  Accessories: new Accessories(),
  MailConfiguration: new MailConfiguration(),
  image: require('@/assets/images/profile-img.png'),
  roles: new RoleCollection(),
  features: new FeatureCollection(),
  DigitalSignage: new DigitalSignage(),
  WeatherLocationModule: new WeatherLocationModule(),
  Orders: new Orders(),
  CombinedUsers: new CombinedUsers(),
  Reservations: new Reservations(),
  Roomcare: new Roomcare(),
  ChatContact: new ChatContact(),
  WelcomeScreen: new WelcomeScreenCollection(),
  RSS: new RSS(),
  Moods: new Moods(),
  CurrencyExchange: new CurrencyExchangeCollection(),
  InstructionCategories: new InstructionCategories(),
  ChatMessageCollection: new ChatMessageCollection(),
  MessageCollection: new MessageCollection(),
};

export default stores;
