
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { SweetModal } from 'sweet-modal-vue';

@Component({
  components: {SweetModal},
})
export default class VODSources extends Vue {
  @Prop() source!: IVIDEOSource | null;
  @Prop() imageSource!: any;
  newSource: IVIDEOSource | null = null;
  alltyps: any = null;
  videoSourceTypes: ISourcesType | null = null;
  authorizationTypes: any = null;

  @Watch('source', {immediate: true})
  setModuleFromProps() {

    if (this.source) {
      this.newSource = JSON.parse(JSON.stringify(this.source));
    } else {
      this.newSource = {
        id: null,
        movieId: null,
        source: '',
        quality: 'SD',
        authorizationType: 'auth_none',
        authorizationKey: '',
        mediaType: this.videoSourceTypes?.defaultSource,
        sort: null,
      };
    }
  }

  mounted() {
    // @ts-ignore
    this.$bus.$on('loadSourcePopup', (response) => {
      this.alltyps = JSON.parse(JSON.stringify(response.deviceTypes));
      this.videoSourceTypes = JSON.parse(JSON.stringify(response.videoSourceTypes));
      this.authorizationTypes = JSON.parse(JSON.stringify(response.authorizationTypes));

      this.setModuleFromProps();
    });
  }

  onMediaTypeClick(type: string) {
    if (type === 'media-library') {
      this.openMediaLibrary();
    } else {
      this.checkStreamUrl();
    }
  }

  openMediaLibrary() {
    this.$emit('openMediaLibrary');
  }

  findObjectByKey(array: any, keyValue: string | null | undefined) {
    return array.find(function (element: any) {
      return element.key === keyValue;
    });
  }

  checkStreamUrl() {
    this.newSource!.source = '';
  }

  save(): void {
    if (this.newSource) {
      if (this.source === null) {
        this.$emit('saveNew', this.newSource);
      } else {
        this.$emit('saveEdit', this.newSource);
      }
      // @ts-ignore
      this.$children[0].close();
    }
  }

  cancel(): void {
    this.$emit('cancel');
    // @ts-ignore
    this.$children[0].close();
  }

  @Watch('imageSource', {immediate: true})
  imageSources() {
    this.newSource!.source = this.imageSource;
  }

}
