
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import stores from '@/stores';
import VueTagsInput from '@johmun/vue-tags-input';
import { SweetModal } from 'sweet-modal-vue';
import SeriesModules from '@/stores/modules/Series';

@Component({
  components: {
    VueTagsInput,
    SweetModal,
  },
})
export default class AttachSource extends Vue {
  @Prop()
  module!: SeriesModules;
  @Prop()
  checkParentType!: any;
  @Prop()
  parentId!: any;
  @Prop()
  episodeType!: any;
  @Prop()
  sourceableType!: any;
  langTag: string = '';
  langTags: object[] = [];
  autocompleteLangItems: any[] = [];
  alltyps: any = null;
  videoSourceTypes: ISourcesType | null = null;
  authorizationTypes: any = null;
  deviceTypes: IDeviceType[] = [];
  newSource: IEpisodeSource = {
    deviceTypeId: null,
    id: null,
    quality: 'SD',
    sort: 1,
    source: '',
    sourceType: 'stream',
    authorizationType: 'auth_none',
    authorizationKey: '',
    mediaType: '',
    moduleId: null,
    sourceableType: null,
  };
  source!: IEpisodeSource | null;
  editeableSource!: IEpisodeSource | null;
  selectedDevices: number[] = [];
  serTag: string = '';
  serTags: object[] = [];
  autocompleteSerItems: any[] = [];

  @Watch('allTypes')
  setTypes() {
    console.log('device type', this.deviceTypes);
    this.alltyps = JSON.parse(JSON.stringify(this.deviceTypes));
    if (this.newSource && this.newSource.deviceTypeId) {
      console.log('this.newSource', this.newSource);
      this.selectedDevices.push(this.alltyps[this.newSource.deviceTypeId - 1].id);
      console.log('allTypes', this.selectedDevices);
    }
  }

  get filteredSerItems() {
    return this.autocompleteSerItems.filter((i) => {
      return i.text.toLowerCase().indexOf(this.serTag.toLowerCase()) !== -1;
    });
  }

  mounted() {

    this.getAllTypes();
    // @ts-ignore
    this.$bus.$on('loadSourcePopup', (response) => {
      this.alltyps = JSON.parse(JSON.stringify(response.deviceTypes));
      this.videoSourceTypes = JSON.parse(JSON.stringify(response.videoSourceTypes));
      this.authorizationTypes = JSON.parse(JSON.stringify(response.authorizationTypes));
    });
  }

  updateDeviceTypeId(id: any) {
    console.log('res', id);
    if (this.editeableSource) {
      // @ts-ignore
      this.newSource.deviceTypeId = id;
    }
  }

  getAllTypes() {
    console.log('check here');
    this.newSource = {
      deviceTypeId: null,
      id: null,
      quality: 'SD',
      sort: 1,
      source: '',
      sourceType: 'stream',
      authorizationType: 'auth_none',
      authorizationKey: '',
      mediaType: this.videoSourceTypes?.defaultSource,
      moduleId: this.module.id,
      sourceableType: null,
    };
    return stores.modules.getSourceTypes()
      .then((response) => {
        this.deviceTypes = response;
        this.alltyps = JSON.parse(JSON.stringify(this.deviceTypes));
      });
  }

  cancel() {
    // @ts-ignore
    this.$children[0].close();
    this.resetForm();
  }

  onSubmit() {
    // @ts-ignore
    this.newSource.sourceableType = this.sourceableType;
    console.log('response', this.newSource);

    if (this.newSource) {
      this.module.createNewSource(Number(this.$route.params.id), this.newSource)
        .then((response: any) => {
          // @ts-ignore
          this.$children[0].close();
          console.log('response', response);
          this.$emit('save');
          this.resetForm();
        }).catch((e: any) => {
        console.log(e);
      });
    }
  }

  resetForm() {
    this.newSource = {
      deviceTypeId: null,
      id: null,
      quality: 'SD',
      sort: 1,
      source: '',
      sourceType: 'stream',
      authorizationType: 'auth_none',
      authorizationKey: '',
      mediaType: '',
      moduleId: this.module.id,
      sourceableType: null,
    };
    this.selectedDevices = [];
  }
}
