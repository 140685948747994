
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import stores from '@/stores';
import Series from '../../../../../stores/modules/Series';
import VueTagsInput from '@johmun/vue-tags-input';
import Axios from 'axios';
import axiosInstance from '../../../../../helpers/axios';
import session from '@/stores/Session';
import { SweetModal } from 'sweet-modal-vue';
import SeriesModules from '@/stores/modules/Series';

@Component({
  components: {
    VueTagsInput,
    SweetModal,
  },
})
export default class AttachSource extends Vue {
  @Prop()
  module!: SeriesModules;
  @Prop()
  checkParentType!: any;
  @Prop()
  parentId!: any;
  @Prop()
  episodeType!: any;
  @Prop()
  sourceableType!: any;
  @Prop() imageSource!: any;

  alltyps: any = null;
  videoSourceTypes: ISourcesType | null = null;
  authorizationTypes: any = null;
  deviceTypes: IDeviceType[] = [];
  newSource: IEpisodeSource | null = null;
  source!: IEpisodeSource | null;
  editeableSource!: IEpisodeSource | null;
  selectedDevices: number[] = [];
  serTag: string = '';
  serTags: object[] = [];
  autocompleteSerItems: any[] = [];
  // @ts-ignore
  episodes: IEpisodes = {
    id: null,
    active: true,
    isTrailer: false,
    sort: 0,
    name: '',
    shortDescription: '',
    fullDescription: '',
    image: null,
    releaseDate: '',
    price: '0',
    rating: '',
    duration: '',
    moduleId: null,
    parentId: null,
    releaseYear: '',
  };

  @Watch('allTypes')
  setTypes() {
    console.log('device type', this.deviceTypes);
    this.alltyps = JSON.parse(JSON.stringify(this.deviceTypes));
    if (this.newSource && this.newSource.deviceTypeId) {
      console.log('this.newSource', this.newSource);
      this.selectedDevices.push(this.alltyps[this.newSource.deviceTypeId - 1].id);
      console.log('allTypes', this.selectedDevices);
    }
  }

  openMediaLibrary() {
    this.$emit('openMediaLibrary');
  }

  checkStreamUrl() {
    this.newSource!.source = '';
  }
  get filteredSerItems() {
    return this.autocompleteSerItems.filter((i) => {
      return i.text.toLowerCase().indexOf(this.serTag.toLowerCase()) !== -1;
    });
  }

  mounted() {
    // @ts-ignore
    this.$bus.$on('loadSourcePopup', (response) => {
      this.alltyps = JSON.parse(JSON.stringify(response.deviceTypes));
      this.videoSourceTypes = JSON.parse(JSON.stringify(response.videoSourceTypes));
      this.authorizationTypes = JSON.parse(JSON.stringify(response.authorizationTypes));

      this.getAllTypes();
    });
  }
  updateDeviceTypeId(id: any) {
    console.log('res', id);
    if (this.editeableSource) {
      // @ts-ignore
      this.newSource.deviceTypeId = id;
    }
  }

  findObjectByKey(array: any, keyValue: string | null | undefined) {
    return array.find(function (element: any) {
      return element.key === keyValue;
    });
  }

  getAllTypes() {
    this.newSource = {
      deviceTypeId: null,
      id: null,
      quality: 'SD',
      sort: 1,
      source: '',
      sourceType: 'stream',
      authorizationType: 'auth_none',
      authorizationKey: '',
      mediaType: this.videoSourceTypes?.defaultSource,
      moduleId: this.module.id,
      sourceableType: null,
    };
  }
  cancel() {
    // @ts-ignore
    this.$children[0].close();
  }
  @Watch('imageSource', {immediate: true})
  imageSources() {
    this.newSource!.source = this.imageSource;
  }

  onMediaTypeClick(type: string) {
    if (type === 'media-library') {
      this.openMediaLibrary();
    } else {
      this.checkStreamUrl();
    }
  }

  onSubmit() {
    // @ts-ignore
    this.newSource.sourceableType = this.sourceableType;

    if (this.newSource ) {
      // @ts-ignore
      this.module.createNewSource(this.$route.params.id, this.newSource)
        .then((response: any) => {
          // @ts-ignore
          this.$children[0].close();
          console.log('response', response);
          this.$emit('save');
        }).catch((e: any) => {
        console.log(e);
      });
    }

  }

}
