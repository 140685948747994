
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import ChatTab from '@/components/Chat/ChatTab.vue';
import ChatTabDetails from '@/components/Chat/ChatTabDetails.vue';


@Component({
    components: {
        ChatTab,
        ChatTabDetails,
    },
})
export default class ChatTabs extends Vue {
    @Prop({ default: false }) projectName!: any;
    @Prop({ default: false }) userRole!: any;
    @Prop({ default: false }) tabs!: string[];
    @Prop({ default: false }) activeChat!: any;
    activeTab: string = 'recent'

    updateActiveTab(clickedTab: any) {
        this.activeTab = clickedTab.toLowerCase();
    }

    @Emit("recentActiveChatUpdate")
    recentActiveChatUpdate(chat: any) {
        return chat;
    }

    @Emit("user-selected")
    handleUserSelected(user: any) {
        return user;
    }
};
