// Import dependencies
import Collection from '@/stores/abstract/Collection';
import Model from '@/stores/abstract/Model';
import axiosInstance from '@/helpers/axios';
import {generateLog} from '@/helpers/functions';
import globalConfig from '@/helpers/globalConfig';
import {RolesEnum} from "@/enums/RolesEnum";

const URL = `${globalConfig.url}/${globalConfig.path}/superadmins`;

export class SuperAdmin extends Model<ISuperAdmin> implements ISuperAdmin {
  status = 0;
  email = null;
  id = null;
  name = '';
  password = '';
  passwordConfirmation = '';
  username = null;
  neighbours = [];
  dateFormat  = '';
  languageId = 1;
  phone = '';
  timeFormat  = '';
  timeZone = '';
  role  = 'Nevron Superadmin';

  constructor(attributes: Partial<ISuperAdmin>, parentCollection?: Collection) {
    super(attributes, parentCollection);
    Object.assign(this, attributes);
  }

  urlRoot(): string {
    return URL;
  }
}

export default class SuperAdminCollection extends Collection<SuperAdmin> {
  routeName = 'superadmins';

  allRoles: IRole[] | null = null

  url(): string {
    return URL;
  }

  model(): new (attributes: ISuperAdmin, collection?: Collection) => SuperAdmin {
    return SuperAdmin;
  }

  build(attributes: ISuperAdmin): SuperAdmin {
    return new SuperAdmin(attributes, this);
  }

  fetchData(index: number = 1, search = '', perpage: number = 20, filtersQuery='') {
    let url = `${this.url()}?page=${index}&perpage=${perpage}`;
    if (search) {
      url = `${url}&search=${search}`;
    }
    if (filtersQuery) {
      url = `${url}&${filtersQuery}`;
    }
    return axiosInstance.get(url)
      .then((response) => response.data)
      .catch((e) => {
        const log = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
        return Promise.reject(e);
      });
  }

  createNewItem(item: ISuperAdmin): Promise<any> {
    return axiosInstance.post(this.url(), item)
      .then((response) => response.data)
      .catch((e) => {
        const log = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
        return Promise.reject(e);
      });
  }

  updateItem(item: ISuperAdmin): Promise<any> {
    return axiosInstance.put(`${this.url()}/${item.id}`, item)
      .then((response) => response.data)
      .catch((e) => {
        const log = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
        return Promise.reject(e);
      });
  }

  checkCurrentPassword(userId:number| null, passwordCheck: any | null): Promise<boolean> {
    return axiosInstance.post(`${this.url()}/check_password/${userId}`, passwordCheck)
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        const log = { route: this.routeName, message: error };
        generateLog(log);
        throw error;
      });
  }



  getAllRoles(): Promise<IRole[]> {
    if (this.allRoles) {
      return Promise.resolve(this.allRoles);
    }
    return axiosInstance.get(`${this.url()}/roles`)
      .then((response) => {
        this.allRoles = response.data.data as IRole[];

        if(!globalConfig.showOperators) {
          this.allRoles = this.allRoles.filter(role => role.name !== RolesEnum.OPERATOR_SUPERADMIN);
        }

        return this.allRoles;
      })
      .catch((e) => {
        const log = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
        return Promise.reject(e);
      });
  }

  delete(data: number[]) {
    return axiosInstance.post(`${this.url()}/delete`, {'ids': data})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: this.routeName,
          message: e,
        };
        generateLog(log);
      });
  }
}
