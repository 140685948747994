
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import stores from '@/stores';
import {mixin as Clickaway} from 'vue-clickaway';
import {SweetModal} from 'sweet-modal-vue';

@Component({
  components: {SweetModal},
  mixins: [Clickaway],
})
export default class ChannelSources extends Vue {
  @Prop() source!: IChannelSource | null;
  @Prop() allTypes!: any;

  newSource: IChannelSource | null = null;
  selectedDevices: number[] = [];
  selectedMediaType: string | null | undefined = '';
  selectedAuth: string | null | undefined = '';
  alltyps: any = null;
  videoSourceTypes: ISourcesType | null = null;
  authorizationTypes: any = null;
  editeableSource!: IChannelSource | null;

  mounted() {
    // @ts-ignore
    this.$bus.$on('loadSourcePopup', (response) => {
      this.alltyps = JSON.parse(JSON.stringify(response.deviceTypes));
      this.videoSourceTypes = JSON.parse(JSON.stringify(response.videoSourceTypes));
      this.authorizationTypes = JSON.parse(JSON.stringify(response.authorizationTypes));

      this.setModuleFromProps();
    });
  }

  @Watch('source', {immediate: true})
  setModuleFromProps() {
    if (this.source) {
      this.newSource = JSON.parse(JSON.stringify(this.source));
    } else {
      this.newSource = {
        deviceTypeId: null,
        authorizationType: 'auth_none',
        authorizationKey: '',
        mediaType: this.videoSourceTypes?.defaultSource,
        id: null,
        quality: 'SD', // (SD.HD.4K. izbereš samo enega)
        sort: null,
        source: '', // (to je URL)
        tvChannelId: null,
      };
    }
    this.editeableSource = this.source;
    this.selectedMediaType = this.newSource?.mediaType;
    this.selectedAuth = this.newSource?.authorizationType;
  }

  @Watch('allTypes')
  setTypes() {
    this.alltyps = JSON.parse(JSON.stringify(this.allTypes));
    if (this.newSource && this.newSource.deviceTypeId) {
      console.log('this.newSource', this.newSource);
      this.selectedDevices.push(this.alltyps[this.newSource.deviceTypeId - 1].id);
      console.log('allTypes', this.selectedDevices);
    }
  }

  updateDeviceTypeId(id: any) {
    if (this.editeableSource) {
      this.editeableSource.deviceTypeId = id;
    }
  }

  findObjectByKey(array: any, keyValue: string | null | undefined) {
    return array.find(function (element: any) {
      return element.key === keyValue;
    });
  }

  save(): void {
    if (this.newSource) {
      const localDevices = this.selectedDevices;
      if (this.source === null) {
        this.$emit('saveNew', {
          source: this.newSource,
          types: localDevices,
        });
      } else {
        this.$emit('saveEdit', this.editeableSource);
      }

      this.newSource = {
        deviceTypeId: null,
        authorizationType: 'auth_none',
        authorizationKey: '',
        mediaType: this.videoSourceTypes?.defaultSource,
        id: null,
        quality: 'SD', // (SD.HD.4K. izbereš samo enega)
        sort: null,
        source: '', // (to je URL)
        tvChannelId: null,
      };
      this.selectedDevices = [];
      console.log('this.selectedDevices', this.selectedDevices);
      // @ts-ignore
      this.$children[0].close();
    }
  }

  cancel(): void {
    this.$emit('cancel');
    // @ts-ignore
    this.$children[0].close();
  }
}
