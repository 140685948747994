
import {Component, Vue, Watch,Provide} from "vue-property-decorator";
import ItemsIndex from '@/components/ListNevronComponents/Items/IndexGeneric.vue';
import stores from "@/stores";
import NevronHeader from "@/components/NevronHeader.vue";
import ChatHeader from "@/components/Chat/ChatHeader.vue";
import ChatBody from "@/components/Chat/ChatBody.vue";
import ChatLeftPannel from "@/components/Chat/ChatLeftPannel.vue";
import routerBase from '@/helpers/routerBase';
import ChatRecent from "@/modules/Chat/ChatRecent.vue";
import { translate } from "@/helpers/functions";

interface User {
  id: number;
  name: string;
  message: string;
  time: string;
}

@Component({
  computed: {
    stores() {
      return stores
    }
  },
  components: {
    ChatRecent,
    NevronHeader,
    ItemsIndex,
    ChatHeader,
    ChatBody,
    ChatLeftPannel
  },
})

export default class ChatIndex extends Vue {
  userData: User[] = [];
  userName: string | null = null;

  projectName: string = '';
  people: IPerson[] = [];
  tabs: ITab[] = [];
  selectedContactId : number | null = null;
  selectedContactName : string | null = null;
  userRole : string | null = null;

  activeChat: any = null;

  activeChatId: number | null = null;
  init: boolean = false;
  chatAlert: boolean = false;


  created() {
    this.projectName = routerBase;
    this.userData = [];
    // @ts-ignore
    this.userRole = stores.admin.roles[0].name;
  }

  mounted() {
    this.init = true;
    this.isSuperAdminUserSetup()
  }
  handleUserSelected(user: any) {
    this.selectedContactId = user.id;
    this.selectedContactName = user.name;
  }

  updateActiveChat(chat :any){
    this.activeChat = chat;
    this.selectedContactId = chat.receiverUserId;
    // Check if Last message is sent by a customer
    if(chat.senderUserType === 'App\\Customer')
    {
      this.selectedContactName =  Vue.prototype.translate(chat.senderUser.firstName) + ' ' + Vue.prototype.translate(chat.senderUser.lastName)
    }
    else{
      this.selectedContactName = Vue.prototype.translate(chat.receiverUser.firstName) + ' ' + Vue.prototype.translate(chat.receiverUser.lastName);
    }
  }

  updateSelectedUser(selectedUser : any){
    this.selectedContactId = selectedUser.id;
    this.selectedContactName = selectedUser.name
  }

  get displayChatBody(){
    return this.activeChat != null || this.selectedContactId != null;
  }

  // handleUserSelected(user: any) {
  //   this.userData = user;
  // }

  handleSelectedUserUpdate(updatedSelectedUser: User[]) {
    this.userData = updatedSelectedUser;
  }
  isSuperAdminUserSetup() {
    let isSuperAdminUserSetup = stores.ChatMessageCollection.isSuperAdminUserSetup()
    if(!isSuperAdminUserSetup)
    {
      this.init = false;
      this.chatAlert = true;
    }
  }

}
