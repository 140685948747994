
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import EditContact from '@/modules/Customers/EditContact.vue';
import virtualList from 'vue-virtual-scroll-list';
import stores from '@/stores';
import EditCustomer from '@/modules/Customers/EditCustomer.vue';
import PreEdit from '@/modules/Customers/Address/PreEdit.vue';
import {mixin as Clickaway} from 'vue-clickaway';
import CustomerAddressEdit from '@/modules/Customers/Address/Edit.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronSearch from '@/components/NevronSearch.vue';
import _ from 'lodash';
import {deletePath} from '@/helpers/DeleteHelper';
import VueBootstrapTypeahead from '@/components/VueBootstrapTypeahead.vue';
import NevronInput from '@/components/NevronInput.vue';
import NevronImageField from '@/components/NevronImageField.vue';
import Axios from 'axios';
import MultiSelect from 'vue-multiselect';
// @ts-ignore
import EventBus from '../../../src/EventBus';
import TableGeneric from '@/components/NevronTableGeneric.vue';
import TableModalGeneric from '@/components/NevronTableModalGeneric.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';
import {translate} from "@/helpers/functions";
import ShowMessageLogPopup from '@/modules/Messaging/Show.vue';
import SingleMoodModal from '@/modules/Moods/SingleMoodModal.vue';
import ItemsIndex from '@/components/ListNevronComponents/Items/IndexGeneric.vue';

Vue.prototype.$bus = EventBus;
@Component({
  components: {
    NevronFooter,
    EditContact,
    virtualList,
    EditCustomer,
    TableModalGeneric,
    TableGeneric,
    PreEdit,
    CustomerAddressEdit,
    NevronImageField,
    NevronHeader,
    Skeleton,
    NevronSearch,
    VueBootstrapTypeahead,
    NevronInput,
    MultiSelect,
    ShowMessageLogPopup,
    SingleMoodModal,
    ItemsIndex

  },
  mixins: [Clickaway],
})

export default class CustomerDetails extends Vue {

  @Prop()
  id: any = '';

  stays: any = '';
  customer: any = null;
  moodHistory: any = null;

  document!: IDocument;
  fullRes: any = null;
  fullResponse: any = null;
  customerIndex: any = '';
  index: number = 1;
  ids: number[] = [];
  getCustomerIndex: any = '';

  customersInstance = stores.Customer;

  tempAcc!: IAccount;
  interests: any[] = [];
  selectedInterests: any[] = [];
  relatedCustomers: any[] = [];
  accountItems: IAccount[] = [];
  searchQuery: string = '';

  response: any = null;
  frontUrl: any = null;
  backUrl: any = null;
  search: any = {
    query: '',
  };
  selectedStay: any = '';
  defaultAddress: ICustomerAddress | null = null;
  selectedContact: IContact | null = null;
  CancelToken: any = Axios.CancelToken;
  source: any;
  indexAccountList: number[] = [];
  allAccountsChecked: boolean = false;
  searchInProgress: boolean = false;
  dragOver: boolean = false;
  preLeave: boolean = false;
  attachableContent: any = null;
  stores = stores;
  toastResponse: any;
  popupMessageLogItem:any  = null;
  birthDateValid: boolean = false;
  selectedItem: IItem | null = null;
  isMore: boolean = false;

  @Watch('customer.dateOfBirth')
  onDateOfBirthChange() {
    this.birthDateValid = new Date(this.customer.dateOfBirth) < new Date(this.maxDateOfBirth);
  }

  get maxDateOfBirth() {
    return new Date().toISOString().split('T')[0];
  }
  

  openNewCustomer() {
    this.getCustomerIndex = 'relatedCustomer';
    // @ts-ignore
    this.$refs.createCustomer.$children[0].open();
  }

  displayPopup(item:any,instance:any){
    this.popupMessageLogItem = item;
    // @ts-ignore
    if(this.$refs.messageLogPopup.$children[0]){
      // @ts-ignore
      this.$refs.messageLogPopup.$children[0]?.open();
    }
  }

  attachRelatedCustomer(data: any) {
    stores.Stays.attachCustomer([data], this.selectedStay)
      .then((resp: any) => {
        this.loadPage();
        setTimeout(() => {
          this.$router.push({name: 'customers.show', params: {id: data}});
        }, 1000);
      })
      .catch((e: any) => {
        console.log(e);
      });
  }

  attachInterest(selectedInterest: []) {
    return stores.Customer.attachInterest(selectedInterest, Number(this.$route.params.id))
      .then((response) => {
        console.log('response', response);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  save() {
    if (this.customer.locked === 1 || this.customer.locked === '1' || this.customer.locked === true) {
      showToaster('danger', ' ', 'The content is locked by the CMS integration module!', 500);
      return
    }

    if (!this.birthDateValid) {
      return;
    }

    stores.Customer.updateItem(this.customer.id, this.customer)
      .then(async (response) => {
        this.onSubmitPersonalData()
        if (response.success) {
          await this.fetchData(Number(this.$route.params.id));
          showToaster('success', Vue.prototype.translate(this.customer.firstName) + ' ' + Vue.prototype.translate(this.customer.lastName), translate('successfully-updated'));
        } else {
          showToaster('danger', Vue.prototype.translate(this.customer.firstName) + ' ' + Vue.prototype.translate(this.customer.lastName), 'Fail to update', response.code);
        }
      }).catch((e: any) => {
        console.log(e);
        showToaster('danger', Vue.prototype.translate(this.customer.firstName) + ' ' + Vue.prototype.translate(this.customer.lastName), 'Fail to update');
      });
  }

  detachCategory(selectedInterest: []) {
    return stores.Customer.detachInterest(selectedInterest, Number(this.$route.params.id))
      .then((response) => {
        console.log('response', response);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  onSubmitPersonalData() {
    const formdata = new FormData();
    formdata.append('identity_number', this.customer.identityNumber);
    formdata.append('document_type', this.customer.documentType);
    formdata.append('frontImagePath', this.customer.documents.frontImagePath);
    formdata.append('backImagePath', this.customer.documents.backImagePath);

    if (this.customer.documents) {
      return stores.Customer.UpdateDocuments(this.customer.id, formdata, this.customer.documents.id)
        .then((response: any) => {
          this.toastResponse = response;

          this.fetchData(Number(this.$route.params.id));
        })
        .catch((e: any) => {
          console.log(e);
          showToaster('danger', Vue.prototype.translate(this.customer.firstName) + ' ' + Vue.prototype.translate(this.customer.lastName), 'Fail to update');
        });
    } else {

      return stores.Customer.saveDocuments(this.customer.id, formdata)
        .then((response: any) => {
          console.log('Success', response);
        })
        .catch((e: any) => {
          console.log(e);
          showToaster('danger', Vue.prototype.translate(this.customer.firstName) + ' ' + Vue.prototype.translate(this.customer.lastName), 'Fail to update');
        });
    }

  }

  onFrontFileChange(e: any) {
    // @ts-ignore
    this.$refs.pickFrontFile.click();
  }

  pickFrontFile(e: any) {
    const file = e.target.files[0];
    this.customer.documents.frontImagePath = file;
    this.frontUrl = URL.createObjectURL(file);

  }

  onBackFileChange(e: any) {
    console.log('yes');
    // @ts-ignore
    this.$refs.pickBackFile.click();
  }

  pickBackFile(e: any) {
    const file = e.target.files[0];
    this.customer.documents.backImagePath = file;
    this.backUrl = URL.createObjectURL(file);
  }

  sortArrays(arrays: any) {
    return _.orderBy(arrays, 'sort', 'asc');
  }

  // user
  attachUser(user: IUser[]) {
    this.customer.user = user;
  }

  detachUser(user: IItem) {
    this.customer.user = null;
  }

  // stays
  attachStays(items: any[]) {
    const newItems = items.filter((el: IItem) => !this.stayIds.includes(el.id));
    this.customer.stays = this.customer.stays.concat(newItems);
  }

  get stayIds() {
    return this.customer.stays.map((el: IItem) => el.id);
  }

  detachStay(item: IItem) {
    this.customer.stays = this.customer.stays.filter((el: any) => el.id !== item.id);
  }

  // promotions
  attachPromotions(items: any[]) {
    const newItems = items.filter((el: IItem) => !this.promotionIds.includes(el.id));
    this.customer.promotions = this.customer.promotions.concat(newItems);
  }

  get promotionIds() {
    return this.customer.promotions.map((el: IItem) => el.id);
  }

  detachPromotion(item: IItem) {
    this.customer.promotions = this.customer.promotions.filter((el: any) => el.id !== item.id);
  }

  detachLanguage(language: ILanguage) {
    this.customer.languages = this.customer.languages.filter((el: ILanguage) => el.id !== language.id);
  }

  /*
   *
   */
  onSubmitStatus() {
    // @ts-ignore
    return stores.Customer.update(this.customer.id, {active: this.customer.active})
      .then(() => {
        console.log('success');
      }).catch((e: any) => {
        console.log(e);
      });
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.fetchData(Number(this.$route.params.id));
    this.getAutocompleteCategories();
    localStorage.removeItem('selectedCustomers');
  }

  @Watch('$route.params.id', {immediate: true})
  loadPage() {
    this.fetchData(Number(this.$route.params.id));
  }

  /**
   *
   */
  popoverMethod() {
    if (this.customer && this.customer.id) {
      return deletePath(this.customer.id);
    }
  }

  deleteCustomer() {
    stores.Customer.deleteItem(this.customer.id)
      .then((response: any) => {
        if (!response.success) {
          throw new Error(response.error.message);
        }
        showToaster('success', translate(this.customer.name), translate('successfully-deleted'));
        this.$router.push({
          name: 'customers',
          query: {tab: 'customers'},
        });
      })
      .catch((error) => {
        showToaster('danger', '', error);
      });
  }

  getAutocompleteCategories() {
    this.CancelToken = Axios.CancelToken;
    this.source = this.CancelToken.source();
    this.interests = [];
    setTimeout(() => {
      return stores.Customer.getAutocompleteCategories(this.searchQuery, this.source.token)
        .then((response) => {
          this.interests = response.data.data;
          this.interests.forEach((interest: any) => {
            interest.name = Vue.prototype.translate(interest.name);
          });
          this.interests = this.interests.filter((interest: any) => interest.parents.length !== 0);
          this.searchInProgress = false;
        })
        .catch((e) => {
          console.log(e);
        });
    }, 500);
  }

  /*
   *
   */
  fetchData(id: number) {
    return stores.Customer.getItem(id)
      .then((response: any) => {
        this.customer = response;
        this.fetchMoodData(this.customer.id)
        this.selectedInterests = [];
        this.customer.interests.forEach((interest: any) => {
          interest.name = Vue.prototype.translate(interest.name);
        });

        for (const address of this.customer.addresses) {
          if (address.default === 1) {
            this.defaultAddress = address;
            break;
          }
        }
      })
      .catch(() => {
        // this.$router.push({name: 'customers'});
      });

  }

  fetchMoodData(id: number, perPage: any = 4) {
    return stores.Moods.fetchMoodData(1, id, perPage)
      .then((response: any) => {
        this.moodHistory = response;
      })

  }

  imageSelected(image: IMediaItem) {
    if (this.customer) {
      this.customer.imageId = (image) ? image.id : null;
    }
  }

  removeContact(contact: IContact) {
    return stores.Customer.deleteCustomerContact(contact)
      .then((response: any) => {
        console.log('success');
        // @ts-ignore
        this.$refs.contact.$children[0].close();
        for (let index = 0; index < this.customer.contacts.length; index++) {
          if (this.customer.contacts[index].id === contact.id) {
            this.customer.contacts.splice(index, 1);
            console.log('removed index:', index);
            break;
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  saveContact(contact: IContact) {
    return stores.Customer.editCustomerContact(contact)
      .then((response: any) => {
        // @ts-ignore
        this.$refs.contact.$children[0].close();
        this.fetchData(Number(this.$route.params.id));
      })
      .catch((e) => {
        console.log(e);
      });
  }

  createNewAddress(newAddress: ICustomerAddress) {
    return stores.Location.createNewAddress(Number(this.$route.params.id), newAddress)
      .then((response: any) => {
        console.log('You have successfully created a customer address!');
        this.fetchData(Number(this.$route.params.id));
      })
      .catch((e) => {
        console.log(e);
      });
  }

  editExistingAddress(newAddress: ICustomerAddress) {
    if (newAddress.id) {
      return stores.Location.editAddress(newAddress.id, Number(this.$route.params.id), newAddress)
        .then((response: any) => {
          console.log('You have successfully edited a customer address!');
          this.fetchData(Number(this.$route.params.id));
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  }

  removeAddress(id: number) {
    if (this.customer.id) {
      return stores.Location.deleteAddress(id, this.customer.id)
        .then((response: any) => {
          console.log('You have successfully deleted this address');
          this.defaultAddress = null;
          if (this.customer.id) {
            this.fetchData(this.customer.id);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  setDefault(selected: ICustomerAddress) {
    if (selected.id) {
      return stores.Location.setDefaultAddress(selected.id, Number(this.$route.params.id))
        .then((response: any) => {
          this.customer.addresses = response;
          for (const address of this.customer.addresses) {
            if (address.default === 1) {
              this.defaultAddress = address;
              break;
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  updateCheck() {
    if (this.indexAccountList.length === this.customer.accounts.length) {
      this.allAccountsChecked = true;
    } else {
      this.allAccountsChecked = false;
    }
  }

  /*
   *
   */
  onSubmitGeneral() {
    const data = {
      salutation: this.customer.salutation,
      gender: this.customer.gender,
      firstName: this.customer.firstName,
      lastName: this.customer.lastName,
      isCompany: this.customer.isCompany,
      company: this.customer.company,
    };

    // @ts-ignore
    return stores.Customer.update(this.customer.id, data)
      .then((response: any) => {
        console.log('saved date', response);
        if (!response.success) {
          throw new Error(response.error.message);
        }
        showToaster('success', translate(this.customer.name), 'successfully-updated');
      })
      .catch((e: any) => {
        showToaster('danger', '', e);
        console.log(e);
      });
  }

  /*
  save() {
    // Why do we need two seperate update calls?
    const generalData = {
      salutation: this.customer.salutation,
      gender: this.customer.gender,
      firstName: this.customer.firstName,
      lastName: this.customer.lastName,
      isCompany: this.customer.isCompany,
      company: this.customer.company,
      imageId: this.customer.imageId,
    };
    const persoanlData = {
      identityNumber: this.customer.identityNumber,
      taxNumber: this.customer.taxNumber,
      dateOfBirth: this.customer.dateOfBirth,
      placeOfBirth: this.customer.placeOfBirth,
      nationality: this.customer.nationality,
    };

    stores.Customer.update(this.customer.id, generalData)
      .then((response: any) => {
        console.log('Success');
      })
      .catch((e: any) => {
        console.log(e);
      });

    stores.Customer.update(this.customer.id, persoanlData)
      .then((response: any) => {
        console.log('Success');
      })
      .catch((e: any) => {
        console.log(e);
      });
  }
  */

  // setupEditlocation(address: ICustomerAddress | null) {
  //   this.tempLoc = null;
  //   if (!address) {
  //     this.tempLoc = {
  //       address: '',
  //       address2: '',
  //       city: '',
  //       country: '',
  //       post: '',
  //       id: null,
  //       customerId: null,
  //       default: 0,
  //       firstName: '',
  //       lastName: '',
  //       company: '',
  //       phoneNumber: '',
  //     };
  //   } else {
  //     this.tempLoc = address;
  //   }

  //   // @ts-ignore
  //   // this.$children[0].close();
  //   // @ts-ignore
  //   this.$refs.loc.$children[0].open();

  // }
  setupEditAccount(account: IAccount) {
    this.tempAcc = account;
  }

  closeAndRefresh() {
    return stores.Customer.getAllCustomerAccounts(Number(this.$route.params.id))
      .then((response: any) => {
        this.customer.accounts = response;
      })
      .catch((e: any) => {
        console.log(e);
      });
  }

  removeLocation(id: number) {
    return stores.Location.deleteAddress(id, Number(this.$route.params.id))
      .then((response: any) => {
        console.log('You have successfully deleted an address');
        this.fetchData(Number(this.$route.params.id));
      })
      .catch((e: any) => {
        console.log(e);
      });
  }
  openResult(item: IItem)
    {
      this.selectedItem = item;
      this.$nextTick(() => {
      // @ts-ignore
        this.$refs.mood.$children[0].open();
      });

    }
    loadMood()
    {
      this.isMore = !this.isMore
      const perPage = this.isMore ? 'all' : 4;
      this.fetchMoodData(this.customer.id, perPage);
    }
}
