
import {Vue,Prop,Component,Emit,Watch} from 'vue-property-decorator';
import ChatTabs from "@/components/Chat/ChatTabs.vue";
import ChatContact from "@/components/Chat/ChatContact.vue";
import stores from "@/stores";
import _ from "lodash";

@Component({
  components: {
    ChatContact,
    ChatTabs
  },
})
export default class ChatLeftPanel extends Vue{


  @Prop({default: false})
  projectName!: string;

  // Search
  searchQuery: string = '';
  searchResults: IChatContact[] = [];
  searchDebounce = _.debounce(this.searchContacts, 500);

  @Prop({default: false}) activeChat: any = null;
  tabs: string[] =  ['Recent','Guest','Staff'];

  @Prop({default: false})
  selectedContactId!: number;



  get customer (){
    return Vue.prototype.translate('customer');
  }
  get role() {
    return stores.admin.role
  }
  get staff (){
    return Vue.prototype.translate('staff-&-access').split('&')[0];
  }

  @Emit("user-selected")
  handleUserSelected(user: any)  {
    return user;
  }

  get isSearching() {
    return this.searchQuery.trim().length > 0;
  }

  // Watchers
  @Watch('searchQuery')
  onSearchQueryChange() {
    this.searchDebounce();
  }

  //Methods
  searchContacts() {
    if (this.searchQuery) {
      stores.ChatContact.searchByFullName(this.searchQuery)
        .then((response: any) => {
          this.searchResults = response.data;
        })
        .catch((e: any) => {
          console.log(e);
        });
    } else {
      this.searchResults = [];
    }
  }

  @Emit("updateActiveChat")
  updateActiveChat(chat: any){
    return chat;
  }
}
