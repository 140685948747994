
import { Component, Prop, Vue } from 'vue-property-decorator';
import stores from '../../stores';
import axiosInstance from '../../helpers/axios';

@Component
export default class ResetPassword extends Vue {
  session = stores.session;
  password: string = '';
  passwordConfirmation: string = '';
  error: boolean = false;
  errorMessage: string = '';

  onSubmit(): void {
    this.error = false;
    if (this.password === this.passwordConfirmation) {
      this.changePassword();
    } else {
      this.errorMessage = 'Passwords don\'t match. Re-enter your password.';
      this.error = true;
    }
  }

  changePassword(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const uri = window.location.href.split('/');
      axiosInstance.patch('/admins/password/change' , { token: uri[uri.length - 1], password: this.password})
      .then((response) => {
          if (response.status !== 200) {
            this.error = true;
            // TODO ne nastavi se error iz backenda
            reject(false);
          } else {
            this.error = false;
            console.log('You have successfully changed your password.');
            this.password = '';
            this.passwordConfirmation = '';
            this.$router.push('/login');
          }
      })
      .catch((error) => {
        this.error = true;
        this.errorMessage = error.response.data.errors[0].message;
      });
    });
  }
}
