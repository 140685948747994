<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light" style="height: 66px;">
      <div class="mr-5">
        <img src="../../../assets/header_log.svg" height="50" />
      </div>
      <button class="navbar-toggler navbar-color" type="button" data-toggle="collapse" data-target="#navbarNav"
              aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" @click="toggleNavbar">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse ml-5" :class="{ 'show': isNavbarOpen }" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item pl-3 pr-3 li-border li-align" :class="{ 'active': isActiveLink('projects') }">
            <router-link class="nav-link" to="/admin/projects">
              PROJECTS
            </router-link>
          </li>
          <li v-if="showOperators()" class="nav-item pl-3 pr-3 li-border li-align" :class="{ 'active': isActiveLink('SuperAdminOperators') }">
            <router-link class="nav-link" to="/admin/operators">
              OPERATORS
            </router-link>
          </li>
          <li class="nav-item pl-3 pr-3 li-border li-align" :class="{ 'active': isActiveLink('superadmin.users') }">
            <router-link class="nav-link active" to="/admin/super-admin-users">
              USERS
            </router-link>
          </li>
          <li class="nav-item pl-3 pr-3 li-align" :class="{ 'active': isActiveLink('SuperAdminDevices') }">
            <router-link class="nav-link active" to="/admin/super-admin-devices">
              DEVICES
            </router-link>
          </li>
        </ul>
        <div class="instructions" @click="openInstructions()">
              <router-link :class="{ 'active': isActiveLink('SuperAdminInstructions') }" 
              to="/admin/super-admin-instructions">
                <NevronIcons type="instructions"></NevronIcons>
              </router-link>
        </div>
        <div class="ml-auto">
          <div class="profile-dropdown" v-if="admin">
            <div class="profile" :class="{ 'active': isDropdownOpen }" @click="toggleDropdown">
              <div class="profile-image">{{ userInitials }}</div>
              <div class="profile-info">
                <div class="name">{{ admin.name }} <span class="ml-1"><i class="fas" :class="isDropdownOpen ? 'fa-chevron-up' : 'fa-chevron-down'"></i></span></div>
                <div class="role">{{ admin.role }}</div>
              </div>
            </div>
            <div class="dropdown-menu" :class="{ 'show': isDropdownOpen }">
              <a class="dropdown-item font-size-14" href="#" @click.prevent="openProfile"><i class="fa fa-user"></i> <span class="ml-1">Profile</span></a>
              <a class="dropdown-item text-danger font-size-14" href="#logout" @click.prevent="logout"> <i class="bx bx-power-off"></i> <span class="ml-1">Logout</span></a>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <SuperAdminDetails ref="admin_profile" :userData="admin"/>
  </div>
</template>

<script>
import { Vue } from 'vue-property-decorator';
import stores from "@/stores";
import globalConfig from "@/helpers/globalConfig";
import axiosInstance from '@/helpers/axios';

import SuperAdminDetails from "@/modules/SuperAdmin/Users/Show.vue";
import DashboardComponents from "@/components/DashboardComposer/DashboardComponents.vue";
import NevronIcons from '../NevronIcons.vue';

export default {
  components: {DashboardComponents, SuperAdminDetails,NevronIcons},

  data() {
    return {
      isNavbarOpen: false,
      isDropdownOpen: false,
      admin: null
    };
  },
  async created() {
    try {
      this.admin = await stores.admin.me();
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    isActiveLink(routeName) {
      return this.$route.name === routeName;
    },
    toggleNavbar() {
      this.isNavbarOpen = !this.isNavbarOpen;
    },
    showFeature() {
      return globalConfig.showBetaFeatures;
    },
    showOperators() {
      if(!this.admin) {
        return false;
      }
      return globalConfig.showOperators && !(this.admin.operatorId > 0);
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    openProfile() {
      this.$nextTick(() => {
        if (this.$refs.admin_profile) {
          this.$refs.admin_profile.$children[0].open();
        } else {
          console.error("SuperAdminDetails modal not found.");
        }
      });
    },
    openUser() {
        this.$nextTick(() => {
          if (this.$refs.admin_profile) {
            this.$refs.admin_profile.$children[0].open();
          } else {
            console.error("SuperAdminDetails modal not found.");
          }
        });
      },
    logout() {
      return axiosInstance.post(`${globalConfig.url}/api/middleware/admin/logout`)
      .catch((e) => {
        console.error(e);
      })
      .then(() => {
        stores.admin.removeToken();
        return this.$router.push('/admin/login');

      });
    }

  },
  computed: {
    userInitials() {
      if (!this.admin || !this.admin.name) return '';
      const initials = this.admin.name.match(/\b\w/g) || [];
      return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    }
  }
}
</script>

<style scoped>
.navbar-light {
  background: #2A3042 !important;
}

.li-border {
  border-right: 1px solid;
}

.li-align {
  margin-top: -33px !important;
  margin-bottom: -33px !important;
}

.li-align.active {
  margin-top: -33px !important;
  margin-bottom: -33px !important;
  background: #3F4455 !important;
}

.li-align .nav-link {
  margin-top: 15px;
}

.navbar-light .navbar-nav .active > .nav-link {
  color: #FFFFFF !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #FFFFFF !important;
}

.profile-dropdown {
  position: relative;
  display: inline-block;
}

.profile {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #2A3042;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.profile.active {
  background-color: #3F4455;
}

.instructions{
  color: #FFFFFF;
  position: absolute;
  right: 8rem;
  bottom: 0.5rem;
  cursor: pointer;
}

.border-radius {
  border-radius: 50%;
}

.navbar-color {
  background: #ffffff;
}

.name {
  color: #FFFFFF;
}

.profile-image {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}

.profile-info {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.name, .role {
  color: #FFFFFF;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0; /* Align dropdown menu to the right */
  min-width: 150px;
  background-color: #FFFFFF; /* White background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  z-index: 1000;
  padding: 10px 0;
}

.dropdown-menu.show {
  display: block;
  margin-left: 57px;
}

.dropdown-item {
  padding: 10px 20px;
  color: #000000; /* Black text color for better contrast on white background */
  text-decoration: none;
  display: block;
  transition: background-color 0.3s ease;
}

.dropdown-item:hover {
  background-color: #f1f1f1; /* Light grey background on hover */
}

@media (max-width: 1001px) {
  .profile {
    display: none;
  }

  .li-align.active {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .li-align {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .li-border {
    border-right: none;
  }
}
</style>
