import axiosInstance from '@/helpers/axios';
import {Vue} from 'vue-property-decorator';
import stores from '@/stores';
import {showToaster} from '@/components/DashboardComposer/helpers';

export function exportData(url: string, ids: number[], columns?: string[]) {
  return axiosInstance.post(url, {ids})
    .then((response) => response.data).catch((e) => {
      const log: any = {
        route: url,
        message: e,
      };
      generateLog(log);
      return e;
    });
}


export function importData(url: string, data: any) {
  return axiosInstance.post(url, data)
    .then((response) => response.data).catch((e) => {
      const log: any = {
        message: e
      };
      generateLog(log);
      return e;
    });
}

export function translatedDisplayName(item: IItem) {
  if (item.username) {
    return item.username;

  } else if (item.name) {
    return Vue.prototype.translate(item.name);

  } else if (item.firstName && item.lastName) {
    const string = `${Vue.prototype.translate(item.firstName)} ${Vue.prototype.translate(item.lastName)}`;
    if (item.salutation) {
      return `${Vue.prototype.translate(item.salutation)} ${string}`;
    }
    return string;

  } else if (item.macEth) {
    return item.macEth;

  } else if (item.macWifi) {
    return item.macWifi;

  } else if (item.city) {
    return Vue.prototype.translate(item.city);

  } else if (item.mainCustomer) {
    return `${Vue.prototype.translate(item.mainCustomer.firstName)} ${Vue.prototype.translate(item.mainCustomer.lastName)}  ${Vue.prototype.translate('stay')}`;

  } else {
    return '';
  }
}

export function generateLog(object: any) {
  console.log('Log:', object);
}

export function translate(key: any) {
  return Vue.prototype.translate(key);
}

export function checkTranslationKey(key: any): boolean {
  return Vue.prototype.i18nte(key);
}

export function titleCase(str: string): string {
  return str.toLowerCase().split(' ').map((word) => {
    return word.replace(word[0], word[0].toUpperCase());
  }).join(' ');
}

export function capitalizeFirst(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}


export function disableSubmitButton(formSelectorId: string) {
  const formSubmitBtn = document.querySelector('[form="' + formSelectorId + '"]') as HTMLFormElement;
  formSubmitBtn.setAttribute('disabled', 'disabled')
}

export function enableSubmitButton(formSelectorId: string) {
  const formSubmitBtn = document.querySelector('[form="' + formSelectorId + '"]') as HTMLFormElement;
  formSubmitBtn.removeAttribute('disabled')
}

export function resetForm(formSelectorId: string) {
  const form = document.getElementById(formSelectorId) as HTMLFormElement;
  form.classList.remove('was-validated');
  form.reset();
}

export function concatenateErrors(response: any) {

  const characterLimit = 50;

  if (response.data && typeof response.data.errors === 'object') {
    const errors = response.data.errors;

    let concatenatedErrors = '';

    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {

        // Check if adding the next error message will exceed the limit
        if ((concatenatedErrors + errors[key]).length <= characterLimit) {
          concatenatedErrors += errors[key] + ', ';

        } else {
          // Add dots if there are more errors
          concatenatedErrors += '...' ;
          break;
        }
      }
    }

    return concatenatedErrors;
  }
}


/**
 * @param formId string \
 * Returns true if valid, else false. \
 * Appends class was-validated to form element. \
 * If the form is false, we show a toastr message.
 */
export function  validateForm(formId: string): boolean {
  const form = document.getElementById(formId) as HTMLFormElement;

  form.classList.add('was-validated');

  if (!form.checkValidity()) {
    showToaster('danger', 'Validation', translate('some-fields-are-required'), 'Validation');
    return false
  }

  return true
}
