
import {Component, Vue, Watch, Prop} from 'vue-property-decorator';
import stores from '@/stores';
import {SweetModal} from 'sweet-modal-vue';
import GlobalFunctions from '@/GlobalFunctions';
import {translate} from "@/helpers/functions";
import {BFormDatepicker} from "bootstrap-vue";
import NevronInput from "@/components/NevronInput.vue";
import CatalogueModule from '../../stores/modules/Catalogue';
import MultiSelect from 'vue-multiselect';
import AttachImage from "@/modules/Media/Attach.vue";

interface IAccessoryField {
  value: string;
}

@Component({
  methods: {translate},
  components: {
    AttachImage,
    NevronInput,
    SweetModal,
    BFormDatepicker,
    MultiSelect
  },
})

export default class CreateAccessory extends Vue {

  // Non-null when editing a playlist
  @Prop({default: null})
  item!: IAccessories | null;
  module!: CatalogueModule;
  // module!: IModule;
  isValidImage: boolean = false;

  accessories: any = {
    name: '',
    shortDescription: '',
    price: '0',
    countable: true,
    onlinePayments: false,
    active: true,
    commentsSection: true,
    dateAndTime: false,
    accessoryDate: '',
    accessoryTime: '',
    accessoryDateMandatory: false,
    accessoryTimeMandatory: false,
    moduleId: localStorage.getItem('moduleId'),
    categories: [],
    imageId: null,
    image: null,
  };

  dateSelected = true;
  timeSelected = true;
  nameRules = {};
  globalFunctions = new GlobalFunctions();
  dateInputs: IAccessoryField[] = [{value: this.currentDate()}];
  timeInputs: IAccessoryField[] = [{value: '11:00'}];
  allCategories: ICategories[] = [];

  // Watch item and update accessory and dateInputs
  @Watch('item')
  onItemChanged() {

    if (this.item) {
      if (this.item.accessoryDate?.length) {
        this.dateInputs = JSON.parse(JSON.stringify(this.item.accessoryDate));
      }
      if (this.item.accessoryTime?.length) {
        this.timeInputs = JSON.parse(JSON.stringify(this.item.accessoryTime));
      }
    }

    this.accessories = {...this.item};
    this.accessories.accessoryCategories?.forEach((categoryItem: ICategories) => {
      if (Vue.prototype.translate(categoryItem.name).trim() !== '') {
        categoryItem.name = Vue.prototype.translate(categoryItem.name);
      }
    });
  }

  getCategories() {
    const module = stores.modules.get(this.$route.params.moduleId) as CatalogueModule;
    module.getAllCategories().then((response: any) => {
      this.allCategories = response.data;
      this.allCategories.forEach((category: ICategories) =>
        category.name = Vue.prototype.translate(category.name));
    })

  }


  validateForm(formRef: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.$forceUpdate();
      this.$nextTick(() => {
        const form = this.$refs[formRef] as HTMLFormElement;
        form.classList.add('was-validated');
        resolve(form.checkValidity());
      });
    });
  }

  async onSubmit() {
    const isValid = await this.validateForm('createPageAccessory');
    if (!isValid) {
      console.log('Form validation failed.');
      return;
    }

    this.accessories.accessoryDate = this.dateInputs;
    this.accessories.accessoryTime = this.timeInputs;

    let promise = this.isEditMode
      ? stores.Accessories.updateItem(this.accessories.id, this.accessories)
      : stores.Accessories.createNewItem(this.accessories);

    promise
      .then((response) => {
        // Clear form fields
        this.accessories = {
          name: '',
          shortDescription: '',
          price: '0',
          countable: true,
          onlinePayments: false,
          active: true,
          commentsSection: true,
          dateAndTime: false,
          accessoryDate: '',
          accessoryTime: '',
          accessoryDateMandatory: false,
          accessoryTimeMandatory: false,
          moduleId: localStorage.getItem('moduleId'),
          accessoryCategories: [],
          imageId: null,
          image: null,
        };

        // Reset dateInputs and timeInputs
        this.dateInputs = [{value: this.currentDate()}];
        this.timeInputs = [{value: '11:00'}];

        const form = this.$refs.createPageAccessory as HTMLFormElement;
        form.classList.remove('was-validated');

        // Close the modal
        this.cancel();

        let eventToEmit = this.isEditMode ? 'updated' : 'created';
        this.$emit(eventToEmit, response.data);
      })
      .catch((e) => {
        console.error(e);
      });
  }

  cancel() {
    this.$emit('cancel', this.accessories.accessoryCategories);
    // @ts-ignore
    this.$children[0].close();
  }

  addDate() {
    this.dateInputs.push({value: this.currentDate(false)});
  }

  addTime() {
    const currentDate = new Date();
    const nextHour = new Date(currentDate);
    nextHour.setMinutes(0, 0, 0);

    // Move to the next hour
    nextHour.setHours(currentDate.getHours() + 1);

    // Use nextHour to set the initial time value
    const formattedHour = nextHour.getHours().toString().padStart(2, '0');
    const formattedMinutes = nextHour.getMinutes().toString().padStart(2, '0');
    const initialTimeValue = `${formattedHour}:${formattedMinutes}`;

    this.timeInputs.push({value: initialTimeValue});
  }

  currentDate(formatDate: boolean = true) {

    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Months are zero-based, so add 1
    const day = currentDate.getDate();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;


    return formatDate ? `${formattedDay}. ${formattedMonth}. ${year}` : `${year}-${month}-${day}`;
  }

  calculateEndTime(selectedTime: string) {
    if (selectedTime) {
      const [hours, minutes] = selectedTime.split(':').map(Number);
      const endTime = new Date(0, 0, 0, hours, minutes + 15);
      const formattedHours = endTime.getHours().toString().padStart(2, '0');
      const formattedMinutes = endTime.getMinutes().toString().padStart(2, '0');
      return `${formattedHours}:${formattedMinutes}`;
    }
    return '';
  }


  get isEditMode() {
    return !!(this.item && this.item.id);
  }

  attachMediaItem(result: any) {

    const mediaObject = result.result;
    this.isValidImage = !!(mediaObject && mediaObject.type && mediaObject.type.startsWith('image/'));

    if (mediaObject) {
      this.accessories.imageId = mediaObject.id;
      this.accessories.image = mediaObject;
    }
  }

  openMediaLibrary() {
    // @ts-ignore
    this.$refs.mediaLibrary.$children[0].open();
  }
}
