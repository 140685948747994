
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ChannelSource from '@/modules/TV/Channels/Sources.vue';
import MultiSelect from 'vue-multiselect';
import { hasPermission } from '@/helpers/permissions';
import virtualList from 'vue-virtual-scroll-list';
import stores from '@/stores';
import TvModule from '@/stores/modules/TvModule';
import Axios from 'axios';
import EditChannel from '@/modules/TV/Channels/Edit.vue';
import VueTagsInput from '@johmun/vue-tags-input';
import timeshift from '@/modules/TV/Channels/Timeshifts.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import AttachEpg from '@/modules/TV/Channels/AttachEpg.vue';
import NevronInput from '@/components/NevronInput.vue';
import DynamicContent from '@/modules/Translation/DynamicContent.vue';
import {Packages} from '@/stores/Package';
import NevronImageField from '@/components/NevronImageField.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';
import Skeleton from '@/modules/Skeleton.vue';
import NevronAdvanceTextBox from '@/components/NevronAdvanceTextBox.vue';
import router from '@/router';
import {translate, validateForm} from "@/helpers/functions";

@Component({
  methods: {translate, hasPermission },
  components: {
    timeshift,
    ChannelSource,
    MultiSelect,
    virtualList, // TODO zakaj tu rabimo virtualList?
    EditChannel,
    VueTagsInput,
    NevronHeader,
    NevronFooter,
    AttachEpg,
    NevronInput,
    DynamicContent,
    NevronImageField,
    Skeleton,
    NevronAdvanceTextBox,
  },
})

export default class TVChannelDetails extends Vue {
  @Prop()
  module!: TvModule;
  // idToEdit: number | null = null;
  sourceToEdit: IChannelSource | null = null;
  allCategories: ICategories[] = [];
  channel: IChannel | null = null;
  searchQuery: string = '';
  indexList: number[] = [];
  allChecked: boolean = false;
  deviceTypes: IDeviceType[] = [];
  sourceTypes: ISourcesType[] | null = null;
  authorizationTypes: IAuthorizationType[] = [];
  searchInProgress: boolean = false;
  CancelToken: any = Axios.CancelToken;
  source: any;
  tag: any = '';
  tags: any[] = [];
  autocompleteItems: any[] = [];
  initialTagsChange: boolean = false;
  initialTagsChange2: boolean = false;
  attachableContent: any = null;
  timeshiftToEdit: ITimeShift | null = null;
  sourcesAlert: any = {
    result: null,
    message: '',
  };
  localText: any = '';
  localKey: any = '';
  packageName: any = [];
  allPackages: Packages[] = [];
  staffEmail: IItemSetting = {
    moduleId: this.module.id,
    itemId: this.channel?.id,
    key: 'staff_email',
    value: '',
    isTransalatable: true,
  };

  stores = stores;

  handleTranslation(data: any) {
    this.localText = data.value;
    this.localKey = data.key;
    // @ts-ignore
    this.$refs.translator.$children[0].open();
  }

  /**
   *
   */
  @Watch('$route', {immediate: true, deep: true})
  changePage() {
    this.refresh(this.$route.params.id);
  }

  submitForm() {
    if (validateForm('update-tv-channel-form')) {
      this.onSubmit();
    }
  }

  onSubmit() {

    if(!this.channel) {
      return;
    }

    return this.module.updateItem(this.channel.id, {
      ...this.channel,
      timeShiftDays: (!this.channel.timeShift) ? 1 : this.channel.timeShiftDays,
      itemSettings: [this.staffEmail]
    })
      .then(async (response) => {
        if (response.responseCode === 200) {
          await this.refresh(this.$route.params.id);
          // @ts-ignore
          showToaster('success', Vue.prototype.translate(this.channel.name), 'successfully updated');
        } else {
          // @ts-ignore
          showToaster('danger', Vue.prototype.translate(this.channel.name), 'Fail to update', response.code);
        }
      })
      .catch((e) => {
        console.log(e);
        // @ts-ignore
        showToaster('danger', Vue.prototype.translate(this.channel.name), 'Fail to update');
      });
  }

  /**
   *
   */
  onSubmitStatus() {
    if (this.channel) {
      return this.module.updateItem(this.channel.id, {active: this.channel.active})
        .then((response: any) => {
          if (!response.success) {
            throw new Error(response.error.message);
          }
          showToaster('success', Vue.prototype.translate(this.channel?.name), 'successfully-deleted');
        }).catch((e: any) => {
          showToaster('danger', '', e);
        });
    }
  }

  imageSelected(image: IMediaItem) {
    if (this.channel) {
      this.channel.imageId = (image) ? image.id : null;
    }
  }

  detachEpg(index: number) {
    if (this.channel && this.channel.id) {
      return this.module.detachEPG(this.channel.id, index)
        .then(() => {
          this.refresh(this.$route.params.id);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  addEPGToChannel(name: string) {
    if (this.channel && this.channel.id) {
      return this.module.attachEPG(this.channel.id, name)
        .then(() => {
          if (this.channel) {
            return this.module.updateItem(this.channel.id, this.channel)
              .then((response) => {
                console.log('Success');
                this.refresh(this.$route.params.id);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  // TODO tega ne rabimo, glej pri adminih
  // lahko daš samo na @Watch ChangePage in je to to ...
  next(nextId: string, e: any) {
    e.preventDefault();
    this.$router.push({name: 'tv.items.show', params: {id: nextId}});
    this.refresh(nextId);
  }

  previous(previousId: string, e: any) {
    e.preventDefault();
    this.$router.push({name: 'tv.items.show', params: {id: previousId}});
    this.refresh(previousId);
  }

  remove() {
    if (this.channel && this.channel.id) {
      return this.module.deleteItem(this.channel.id)
        .then((response: any) => {
          console.log(response);
          if(!response.success) {
            throw new Error(response.error.message);
          }
          showToaster('success', Vue.prototype.translate(this.channel?.name), Vue.prototype.translate('successfully-deleted'));
          // @ts-ignore
          this.$refs.edit.$children[0].close();
          this.$router.push({name: 'tv.index', query: {tab: 'list'}});
        })
        .catch((error) => {
          showToaster('danger', '', error);
        });
    }
  }

  editExistingTimeshift(ts: any) {
    if (this.channel && this.channel.id) {
      return this.module.editExistingTimeshifte(this.channel.id, ts)
        .then(() => {
          console.log('You have succsesfully edited a channel timeshift!');
          this.timeshiftToEdit = null;
          this.refresh(this.$route.params.id);
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  }

  createNewTimeshift(ts: any) {
    if (this.channel && this.channel.id) {
      this.module.createNewTimeshift(this.channel.id, ts)
        .then((response: any) => {
          console.log('You have successfully created a channel timeshift!');
          this.timeshiftToEdit = null;
          this.refresh(this.$route.params.id);
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  }

  deleteTimeshift(id: number) {
    if (this.channel && this.channel.id) {
      return this.module.deleteTimeshift(this.channel.id, id)
        .then(() => {
          console.log('You have successfully deleted a channel source!');
          this.refresh(this.$route.params.id);
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  }

  back() {
    return stores.Folder.getFolder(this.attachableContent.mediaFolderId)
      .then((response) => {
        this.attachableContent = response;
        this.attachableContent.children = this.attachableContent.folders.concat(this.attachableContent.files);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  forward(id: number) {
    return stores.Folder.getFolder(id)
      .then((response) => {
        this.attachableContent = response;
        this.attachableContent.children = this.attachableContent.folders.concat(this.attachableContent.files);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getImages() {
    return stores.Folder.getFolder(1)
      .then((response) => {
        this.attachableContent = response;
        this.attachableContent.children = this.attachableContent.folders.concat(this.attachableContent.files);
        // @ts-ignore
        this.$refs.attach.$children[0].open();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  reload(channel: IChannel) {
    return this.module.editSelectedChannel(channel)
      .then((response) => {
        console.log('You have successfuly edited selected TV channel!');
        this.refresh(this.$route.params.id);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getAutocompleteCategories(text: string) {
    this.CancelToken = Axios.CancelToken;
    this.source = this.CancelToken.source();
    if (!this.searchInProgress) {
      this.searchInProgress = true;
      setTimeout(() => {
        return this.module.getAutocompleteCategories(this.searchQuery, this.source.token)
          .then((response) => {
            this.autocompleteItems = [];

            for (const iterator of response) {
              this.autocompleteItems.push({text: iterator.name});
            }
            this.$router.push({query: {query: this.searchQuery}});
            this.searchInProgress = false;
          })
          .catch((e) => {
            console.log(e);
          });
      }, 500);
    } else {
      this.source.cancel();
    }
  }

  get filteredItems() {
    return this.autocompleteItems.filter((i) => {
      return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
    });
  }

  getSourceData(e: any = null) {
    if (e) {
      e.preventDefault();
    }

    Promise.all([
      stores.modules.getSourceTypes(),
      stores.modules.getVideoSourceTypes(),
      stores.modules.getAuthorizationTypes()
    ]).then(([sourceTypes, videoSourceTypes, authorizationTypes]) => {
      this.deviceTypes = sourceTypes;
      this.sourceTypes = videoSourceTypes;
      this.authorizationTypes = authorizationTypes;
      // @ts-ignore
      this.$bus.$emit('loadSourcePopup', { deviceTypes: sourceTypes, videoSourceTypes: videoSourceTypes, authorizationTypes: authorizationTypes });
    });
  }

  checkAll() {
    if (!this.allChecked) {
      this.indexList = [];
      if (this.channel) {
        for (let index = 0; index < this.channel.sources.length; index++) {
          this.indexList.push(index);
        }
      }
      this.allChecked = true;
    } else {
      this.indexList = [];
      this.allChecked = false;
    }
  }

  updateCheck() {
    if (this.channel) {
      this.allChecked = this.indexList.length === this.channel.sources.length;
    }
  }

  unknownClicked() {
    return null;
  }

  save() {
    if (this.channel) {
      return this.module.editSelectedChannel(this.channel)
        .then((response) => {
          console.log('You have successfully edited selected TV channel!');
          this.refresh(this.$route.params.id);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  saveEdit(tempChannel: IChannel) {
    return this.module.editSelectedChannel(tempChannel)
      .then((response) => {
        console.log('You have successfuly edited selected TV channel!');
        this.refresh(this.$route.params.id);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  search() {
    this.CancelToken = Axios.CancelToken;
    this.source = this.CancelToken.source();
    if (!this.searchInProgress) {
      this.searchInProgress = true;
      setTimeout(() => {
        if (this.channel && this.channel.id) {
          return this.module.searchSelectedChannelSources(this.channel.id, this.searchQuery, this.source.token)
            .then((response) => {
              if (this.channel) {
                this.channel.sources = response;
              }
              this.$router.push({query: {query: this.searchQuery}});
              this.searchInProgress = false;
            })
            .catch((e) => {
              console.log(e);
            });
        }
      }, 500);
    } else {
      this.source.cancel();
    }
  }

  createNewSource(sources: any) {
    for (const iterator of sources.types) {
      setTimeout(() => {
        sources.source.deviceTypeId = iterator;
        if (this.channel && this.channel.id) {
          this.module.createNewSource(this.channel.id, sources.source)
            .then((response: any) => {
              if (response.message) {
                this.sourcesAlert.message = response.message;
                this.sourcesAlert.result = response.result;
              } else {
                this.sourcesAlert.message = 'You have succsesfully created a channel source!';
                this.sourcesAlert.result = true;
                this.getSourceData();
              }
              this.getSetChannelSources();
            }).catch((e: any) => {
            console.log(e);
          });
        }
      }, 100); // if timeout is not present then it will create all sources with the same device type
    }

  }

  private getSetChannelSources(): void {
    if (this.channel) {
      //@ts-ignore
      this.module.getSelectedChannelSources(this.channel.id).then((data) => {
        //@ts-ignore
        this.channel.sources = data;
      });
    }

  }


  selectedTimeshift(selected: ITimeShift) {
    this.timeshiftToEdit = selected;
  }

  selectedSource(source: IChannelSource) {
    this.sourceToEdit = source;
    console.log(this.sourceToEdit);
  }

  editExistingSource(source: any) {
    if (this.channel && this.channel.id) {
      console.log('editable source', source);
      return this.module.editExistingSource(this.channel.id, source)
        .then((response) => {
          console.log('edited log', response);
          if (response.message) {
            this.sourceToEdit = null;
            this.sourcesAlert.message = response.message;
            this.sourcesAlert.result = response.result;
          } else {
            this.sourcesAlert.message = 'You have succsesfully edited a channel source!';
            this.sourcesAlert.result = true;
            console.log('You have succsesfully edited a channel source!');
            this.sourceToEdit = null;
            this.refresh(this.$route.params.id);
          }
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  }

  itemSetting(settings: any) {
    for (const index of settings) {
      if(index.key == 'staff_email') {
        this.staffEmail.value = index.value;
      }
    }
  }

  refresh(id: string) {
    return this.module.getSelectedChannel(Number(id))
      .then((response: any) => {
        this.channel = {
          ...response,
          timeShiftDays: (response.timeShiftDays && response.timeShiftDays >= 1) ? response.timeShiftDays : 1,
        };
        this.tags = [];
        this.channel?.categories?.forEach((category: ICategories) =>
          category.name = Vue.prototype.translate(category.name));

        this.channel?.packages?.forEach((item: IPackage) =>
          item.name = Vue.prototype.translate(item.name));

        // loading Item settings
        this.itemSetting(response.itemSettings);
      })
      .catch((e: any) => {
        console.log(e);
      });
  }

  deleteSource(id: number) {
    if (this.channel && this.channel.id) {
      return this.module.deleteSource(this.channel.id, id)
        .then(() => {
          this.refresh(this.$route.params.id);
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    // this.getSourceData();
    this.refresh(this.$route.params.id);
    this.getSourceData();
    this.module.getAllCategories()
      .then((response: any) => {
        this.allCategories = response.data;
        this.allCategories.forEach((category: ICategories) =>
          category.name = Vue.prototype.translate(category.name));
      });
    this.getPackages();
    // @ts-ignore
    this.$bus.$on('openTranslator', (localText: any, localKey: any, referenceKey: any) => {
      this.localText = localText;
      this.localKey = localKey;
      // @ts-ignore
      this.$refs.translator.$children[0].open();
    });
  }

  getPackages() {
    stores.Package.getAllPackages()
      .then((response) => {
        this.allPackages = response;
        this.allPackages.forEach((item: IPackage) =>
          item.name = Vue.prototype.translate(item.name));
      });
  }

  cancleEdit() {
    this.sourceToEdit = null;
  }
}
