
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import stores from '../../stores';
import routerBase from '@/helpers/routerBase';
import globalConfig from '@/helpers/globalConfig';
// @ts-ignore
import EventBus from '../../../src/EventBus';

@Component
export default class LoginItem extends Vue {
  username: string = '';
  password: string = '';
  rememberMe: boolean = false;
  session = stores.session;

  inDevelopment = globalConfig.env === 'development';

  redirectPath: string | null = null;

  errorMessage: string | null = null;

  admin = stores.admin;
  image = require('@/assets/images/profile-img.png');

  @Watch('admin.isLoggedIn', {immediate: true})
  adminWatch() {
    this.redirect();
  }

  redirect() {
    // @ts-ignore
    this.$bus.$emit('logged', 'User logged');
    if (this.admin.isLoggedIn) {
      this.$router.replace(this.redirectPath || '/dashboard');
    }
  }

  async beforeCreate() {
    const currentProjectId = routerBase;
    await stores.master.getSpecificProjectInfo(currentProjectId)
      .then((response: any) => {
        if (response.error) {
          this.$router.replace({name: '404', query: {error: `Project \"${this.session.projectId}\" does not exist`}});
        }
      });
  }

  mounted() {
    Vue.prototype.WebsiteTitle.setModuleName('login');
    this.redirectPath = this.$route.query.redirect as string;
  }

  async onSubmit() {
    try {
      const loginResponse = await stores.admin.login(this.username, this.password, this.rememberMe);
      this.errorMessage = null;
      EventBus.$emit('login');
      this.redirect();
    } catch (error) {
      console.error(error);
      this.errorMessage = (error && error.message) || (error && error.data && error.data.message) || 'An unexpected error occurred.';
      throw error;
    }
  }


  defaultLogo(event?: any) {
    if (!event) {
      return require('../../../assets/nevron_logo.png');
    }
    event.target.src = require('../../../assets/nevron_logo.png');
  }

}
