
import {Component, Prop, Vue} from 'vue-property-decorator';
import stores from '@/stores';
import {SweetModal} from 'sweet-modal-vue';
import GlobalFunctions from '@/GlobalFunctions';

@Component({
  components: {
    SweetModal,
  },
})
export default class CreateLocation extends Vue {

  @Prop({default: false})
  emitCreated!: boolean;

  location: any = {
    name : '',
  };

  globalFunctions = new GlobalFunctions();
  form!: HTMLFormElement;

  mounted() {
    this.form = document.getElementById('create-form-location')! as HTMLFormElement;
    // listen for escape button click
    document.addEventListener('keydown', this.keyDown);
    // listen for submit and validate form
    this.form.addEventListener('submit', (event: any) => {
      event.preventDefault();
      if (this.form.checkValidity() === true) {
        event.stopPropagation();
        this.onSubmit();
      }
      if (this.form.checkValidity() === false) {
        event.stopPropagation();
      }
      this.form.classList.add('was-validated');
    }, false);

  }

  beforeDestroy() {
    document.removeEventListener('keydown', this.keyDown);
  }

  onSubmit() {
    return stores.Rooms.createNewItems(this.location)
      .then((response) => {
        console.log(response);
        this.cancel();
        this.$router.push({name: 'zones.show', params: {id: response.data.id}});
        this.$emit('created');
      })
      .catch((e) => {
        console.log(e);
      });
  }

  keyDown(event: any) {
    if (event.key === 'Escape') {
      this.cancel();
    }
  }

  cancel() {
    // @ts-ignore
    this.$children[0].close(); // close modal
    this.form.classList.remove('was-validated'); // remove validation styles
  }
}
