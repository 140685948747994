
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import stores from '@/stores';
import AttachFile from '@/modules/Media/Attach.vue';
import axiosInstance from '@/helpers/axios';
import {showToaster} from "@/components/DashboardComposer/helpers";
import {isImage, isVideo} from "@/helpers/files";

@Component({
  components: {
    AttachFile,
  },
})
export default class NevronFileField extends Vue {
  @Prop()
  module!: IModule;

  @Prop({default: null})
  file!: IMediaItem | null;

  @Prop({default: null})
  title!: string;

  @Prop({default: () => []})
  allowedFileTypes!: string[];

  @Prop({default: true})
  decoration!: boolean;

  localFile: IMediaItem | null = null;

  loading = false;
  renderPotal = false;

  isImage = isImage;
  isVideo = isVideo;

  mounted() {
    this.localFile = JSON.parse(JSON.stringify(this.file));
  }

  get backupMediaFolderName() {
    const routeName = this.module ? this.module.routeName: '';
    if (routeName) {
      return `${routeName[0].toUpperCase()}${routeName.slice(1)}`;
    }
  }

  @Watch('file')
  setLocalfile(file: IMediaItem) {
    this.localFile = JSON.parse(JSON.stringify(this.file));
  }

  getHeaders() {
    return stores.File.getHeader();
  }

  getFilePath(): string {
    return axiosInstance.defaults.baseURL + stores.File.uploadUrl();
  }

  fileDropped(meta: any, file: IMediaItem) {
    this.fileSelected(file);
  }

  fileFromMediaLibrary(result: any) {
    this.fileSelected(result.result);
  }

  fileSelected(file: IMediaItem | null) {
    if (file && !this.isFileTypeAllowed(file)) {
      showToaster('danger', '', Vue.prototype.translate('unsupported-file-type'));
    } else {
      this.localFile = file;
      this.$emit('selected', file);
    }
    this.loading = false;
  }
  openGalleryModal() {
    // Workaround: for some reason the portal has to be rendered before the modal is opened, otherwise ref is not defined
    this.renderPotal = true;
    this.$nextTick(() => {
      // @ts-ignore
      this.$refs.attach.$children[0].open();
    });
  }

  isFileTypeAllowed(file: IMediaItem) {
    if (this.allowedFileTypes.length === 0) {
      return true;
    }
    return this.allowedFileTypes.includes(file.type);
  }



}
