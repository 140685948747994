
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {SweetModal} from 'sweet-modal-vue';
// @ts-ignore
import Sortable from 'vue-drag-sortable';
import NevronInput from '@/components/NevronInput.vue';
import stores from '@/stores';
import {Dashboards} from '@/stores/Dashboard';
import NevronImageField from '@/components/NevronImageField.vue';
import WeatherLocationModule, { WeatherLocationModuleItem } from '@/stores/modules/WeatherLocationModule';

@Component({
  components: {
    SweetModal,
    Sortable,
    NevronInput,
    NevronImageField,
  },
})
export default class EditPanel extends Vue {
  @Prop()
  panel: any | null;
  @Prop()
  panelIndex: any | null;
  @Prop()
  noImageIcon: any | null;
  localPanel = null;

  dragData: any = {};
  services: any = [];
  module!: Dashboards;

  selectedWeather: WeatherLocationModuleItem | null = null;
  weathers: WeatherLocationModuleItem[] | [] = [];
  selectedWeatherModule: any = null;
  weatherModules: IModule[] | [] = [];

  hidePanelTypes: any = ['mood', 'weather', 'never-opened', 'banner'];

  @Watch('panel')
  loadProp() {
    this.localPanel = JSON.parse(JSON.stringify(this.panel));
  }

  mounted() {
    this.module = new Dashboards({name: 'dashboard', routeName: 'name'});
    stores.modules.modules()
      .then((response) => {
        this.services = response;
      });
  }

  panelTitle (type:any) {
    if (type === 'mood') {
      return 'manage-my-mood-panel';
    } else if (type === 'weather') {
      return 'manage-weather-panel';
    } else if (type === 'never-opened') {
      return 'manage-never-opened-panel';
    } else if (type === 'banner') {
      return 'manage-banner-panel';
    } else {
      return 'manage-collection-panel';
    }
  }

  sort(e: any) {
    const {oldIndex, newIndex} = e;
    console.log(oldIndex, newIndex);
  }

  openTranslator(localText: any, localKey: any, referenceKey: any) {
    // @ts-ignore
    this.$bus.$emit('openTranslator', localText, localKey, referenceKey);
  }

  imageSelected(image: IMediaItem) {
    if (this.localPanel) {
      // @ts-ignore
      this.localPanel.dashboardBackground = (image) ? image.id : null;
      // @ts-ignore
      this.localPanel.background = image;
    }
  }

  sorting(e: any) {
    const {oldIndex, newIndex} = e;
    this.rearrange(this.panel.elements, oldIndex, newIndex);
  }

  sortend(e: any, list: any) {
    const {oldIndex, newIndex} = e;
    this.rearrange(list, oldIndex, newIndex);
  }

  rearrange(array: any, oldIndex: any, newIndex: any) {
    if (oldIndex > newIndex) {
      array.splice(newIndex, 0, array[oldIndex]);
      array.splice(oldIndex + 1, 1);
    } else {
      array.splice(newIndex + 1, 0, array[oldIndex]);
      array.splice(oldIndex, 1);
    }
  }

  updateRatio(option: string) {
    // @ts-ignore
    this.localPanel.elementType = option;
  }

  isSelected(ration: string) {
    // @ts-ignore
    return this.localPanel.elementType === ration;
  }

  cancel() {

    if (localStorage.getItem('createPanel') === 'true') {
      this.$emit('close');
    }
    // @ts-ignore
    this.$children[0].close();


    if(this.localPanel === null) return
    // @ts-ignore
    if(this.localPanel.panelType === 'weather')
      this.onWeatherPanelCancel()
  }

  next() {

    if(this.localPanel === null) return

    // @ts-ignore
    if(this.localPanel.panelType === 'weather')
      this.onWeatherPanelSubmit()

    const output = {
      panel: JSON.parse(JSON.stringify(this.localPanel)),
      panelIndex: this.panelIndex,
    };
    this.$emit('next', output);
    // @ts-ignore
    this.$children[0].close();
  }

  @Watch('localPanel')
  onLocalPanelOpen() {
    if(this.panel === null) return
    if(this.panel.panelType === 'weather')
      return this.onWeatherPanelOpen()
  }

  async onWeatherPanelOpen()
  {
      this.weatherModules = await stores.modules.getSpecificModule('WEATHER');
      this.weatherModules.forEach((weatherModule) => {
          // @ts-ignore
          if(weatherModule.id == this.localPanel.linkModuleId)
            this.selectedWeatherModule = weatherModule
      })

      let weatherLocationModule = new WeatherLocationModule(this.selectedWeatherModule);
      let weatherLocations = await weatherLocationModule.getAllWeatherLocations();
      this.weathers = weatherLocations.data

      this.weathers.forEach((weatherLocation) => {
          // @ts-ignore
          if(weatherLocation.id == this.localPanel.linkElementId)
            this.selectedWeather = weatherLocation
      })
  }

  onWeatherPanelSubmit()
  {
    // @ts-ignore
    this.localPanel.linkElementId = this.selectedWeather.id
    // @ts-ignore
    this.localPanel.linkModuleId = this.selectedWeatherModule.id
  }


  async onWeatherPanelCancel()
  {
      this.weatherModules.forEach((weatherModule) => {
          // @ts-ignore
          if(weatherModule.id == this.localPanel.linkModuleId)
            this.selectedWeatherModule = weatherModule
      })

      let weatherLocationModule = new WeatherLocationModule(this.selectedWeatherModule);
      let weatherLocations = await weatherLocationModule.getAllWeatherLocations();
      this.weathers = weatherLocations.data

      this.weathers.forEach((weatherLocation) => {
          // @ts-ignore
          if(weatherLocation.id == this.localPanel.linkElementId)
            this.selectedWeather = weatherLocation
      })
  }

  async loadWeatherLocations()
  {
      let weatherLocationModule = new WeatherLocationModule(this.selectedWeatherModule);
      let weatherLocations = await weatherLocationModule.getAllWeatherLocations();
      this.weathers = weatherLocations.data
  }

}
