import axios, {AxiosResponse} from 'axios';

import {loadProgressBar} from 'axios-progress-bar';
import session from '@/stores/Session';
import stores from '@/stores';
// @ts-ignore
import EventBus from '../../src/EventBus';
import {mergeLocalization} from '@/helpers/helpers';
// TODO sej bi lahko v session.project shranil kar objekt z vsemi nastavitami?

const axiosInstance = axios.create({
  baseURL: session.project === null ? '' : session.project!.url,
});

if (session.project) {
  axiosInstance.interceptors.request.use((request) => {

    const token = sessionStorage.getItem(session.project!.id + '-token');
    request.headers = {
      'Authorization': 'Bearer ' + token,
      'X-Project': session.project!.id,
      'Accept': 'application/json',
    };

    return request;
  });
}

axiosInstance.interceptors.response.use((response): AxiosResponse => {
  if (response.config.method && ['put', 'patch', 'post'].includes(response.config.method)) {
    EventBus.$on('login', () => {
      stores.Language.loadTranslations();
    });
    if(!response.data.isSuperAdmin) {
      stores.Language.loadTranslations();
      if (!response.data.accessToken) {
        let translatables = '';
        if (response.data.dashboard) {
          // tslint:disable-next-line:no-shadowed-variable
          stores.Language.loadTranslations();
        } else {
          translatables = response.data.data !== undefined ? response.data.data.translatables : '';
          if (translatables !== undefined) {
            mergeLocalization(translatables);
          }
          if (response.data.data?.loadTranslation !== undefined) {
            stores.Language.loadTranslations();
          }
        }
      }
    }
  }
  if (response.data && response.data.error) {
    console.error(response.data.error);
  }
  return response;
});

loadProgressBar({
    showSpinner: false,
  },
  axiosInstance,
);
export default axiosInstance;
