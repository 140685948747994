import ItemsCollection, {Item} from './ItemsCollection';

import Axios from 'axios';
import Module from './Module';
import axiosInstance from '@/helpers/axios';
import stores from '@/stores/index';
import {generateLog} from '@/helpers/functions';

const CancelToken: any = Axios.CancelToken;
let cancel: any;

export class AODModuleItem extends Item implements ISeries {
  active!: boolean;
  sort!: number | null;
  title!: string;
  originalTitle!: string;
  shortDescription!: string;
  fullDescription!: string;
  image?: string | null | undefined;
  // @ts-ignore
  imageId: number | null | undefined;
  releaseYear!: number | null;
  endYear!: number | null;
  studio!: string;
  price!: string;
  rating!: string;
  adult!: boolean;
  origin!: string;
  moduleId!: number | null;

}

export class AODItemsCollection extends ItemsCollection<AODModuleItem> {
  model() {
    return AODModuleItem;
  }

  url(): string {
    return this.module.url();
  }

  getSeries() {
    return axiosInstance.get(this.url())
      .then((response) => response.data);
  }

}

export default class AODModule extends Module<AODItemsCollection> {

  routeName = 'aod';

  Collection() {
    return AODItemsCollection;
  }

  url() {
    return `/aod/${this.id}`;
  }

  urlItem() {
    return `/aod/${this.id}/aod`;
  }

  crewurl() {
    return 'crews';
  }



  casturl() {
    return 'casts';
  }

  urlCategory() {
    return `/aod/${this.id}/category`;
  }

  urlItems() {
    return `/aod/${this.id}/aod`;
  }

  updateCategory(id: any, category: ICategories) {
    return axiosInstance.put(`${this.urlCategory()}/${id}`, category)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }

  createNewItem(item: any) {
    return axiosInstance.post(`aod/${this.id}/aod`, item)
      .then((response) => {
        return response.data;
      }).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }

  getAllCategories(search: string | null = null) {
    if (cancel !== undefined) {
      cancel();
    }
    const searchString = (Boolean(search)) ? `search=${search}` : '';
    return axiosInstance.get(`${this.urlCategory()}?${searchString}`, {
      cancelToken: new CancelToken(function executor(c: any) {
        cancel = c;
      }),
    })
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }

  getSpecificCategory(id: number) {
    return axiosInstance.get(`${this.urlCategory()}/${id}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: 'Aod',
          message: e,
        };
        generateLog(log);
      });
  }

  fetchData(index: number = 1, search: string | null, perpage: number | null) {
    if (cancel !== undefined) {
      cancel();
    }

    if (Boolean(search)) {
      return axiosInstance.get(`${this.urlItems()}?search=${search}`, {
        cancelToken: new CancelToken(function executor(c: any) {
          cancel = c;
        }),
      })
        .then((response) => response.data).catch((e) => {
          const log: any = {
            route: 'Series',
            message: e,
          };
          generateLog(log);
        });
    } else {
      return axiosInstance.get(`${this.urlItems()}?page=${index}&perpage=${perpage}`, {
        cancelToken: new CancelToken(function executor(c: any) {
          cancel = c;
        }),
      })
        .then((response) => response.data).catch((e) => {
          const log: any = {
            route: 'Series',
            message: e,
          };
          generateLog(log);
        });
    }
  }

  getEpisodesDetails(id: number, seasonId: number, parentType: string) {
    return axiosInstance.get(`aod/${this.id}/${parentType}/${seasonId}/episodes/${id}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }

  getCrewDetails(id: number) {

    return axiosInstance.get(`${this.crewurl()}/${id}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }

  getCastDetails(id: number) {
    return axiosInstance.get(`${this.casturl()}/${id}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }

  attachCategoryToPage(series: AODModule, n: any) {
    return axiosInstance.post(`${this.urlItem()}/${series.id}/attach`, {name: n.tag.text})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }

  getAutocompleteCategories(query: string, source: any) {
    return axiosInstance.get(`${this.urlCategory()}/unattached?query=${query}`, {cancelToken: source.token})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }

  createNewSource(id: number, sources: any) {
    return axiosInstance.post(`/vod/${this.id}/movies/${id}/sources`, sources)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }

  createNewCategory(category: ISeriesCategories) {
    return axiosInstance.post(`aod/${this.id}/category`, category)
      .then((response) => {
        return response.data;
      }).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }

  deleteItem(id: number) {
    return axiosInstance.delete(`${this.urlItem()}/${id}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }

  deleteItems(ids: any[]) {
    return axiosInstance.post(`${this.urlItem()}/delete`, {ids})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }

  getCategoryItems(id: number, search: string) {
    return axiosInstance.get(`aod/${this.id}/genres`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }

  updateItem(id: number, series: IAod) {
    return axiosInstance.put(`${this.urlItem()}/${id}`, series)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }

  getAODDetails(id: number) {
    return axiosInstance.get(`aod/${this.id}/aod/${id}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }

  createEpisodesItem(item: any, checkParentType: string, parentId: number) {
    return axiosInstance.post(`aod/${this.id}/${checkParentType}/${parentId}/episodes`, item)
      .then((response) => {
        return response.data;
      }).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }

  deleteCategory(id: number) {
    return axiosInstance.delete(`${this.urlCategory()}/${id}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }

  fetchCrewCastData(index: number | null, search: string | null, perPage: number | 10) {
    // if (cancel !== undefined) {
    //   this.cancel();
    // }
    return axiosInstance.get(`crews-casts?search=${search}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }

  sortCategories(id: number, newPosition: number) {
    return axiosInstance.patch(`${this.urlCategory()}/${id}/resort`, {sort: newPosition})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }

  deleteSource(episodeId: number, sourceID: number) {
    return axiosInstance.delete(`/aod/${this.id}/delete/source/${sourceID}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }

  editExistingSource(episodeId: number, source: any) {
    return axiosInstance.put(`/aod/${this.id}/episodes/${episodeId}/sources/${source.id}`, source)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }

  detachCrew(selectedCrew: any, seriesId: number) {
    // @ts-ignore
    return axiosInstance.post(`/aod/${this.id}/aod/${seriesId}/crews/detach`, {id: selectedCrew})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }

  detachCast(selectedCast: any, seriesId: number) {
    // @ts-ignore
    return axiosInstance.post(`aod/${this.id}/aod/${seriesId}/casts/detach`, {id: selectedCast})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }

  detachTrailer(parentType: string, seriesId: number | null, episodeId: number) {
    // @ts-ignore
    return axiosInstance.delete(`/aod/${this.id}/${parentType}/${seriesId}/episodes/${episodeId}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }
  attachCast(idList: number[], seriesId: number) {
    return axiosInstance.post(`aod/${this.id}/aod/${seriesId}/casts/attach`, {ids: idList})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }

  attachCrew(idList: number[], seriesId: number) {
    return axiosInstance.post(`aod/${this.id}/aod/${seriesId}/crews/attach`, {ids: idList})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }

  getCatalogueCategories() {
    return axiosInstance.get(this.urlCategory())
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }

  getSubItems(id: number, search: string) {
    return axiosInstance.get(`${this.urlCategory()}/${id}/content?search=${search}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }

  saveCrewItems(crew: ICrew) {
    return axiosInstance.put(`crews/${crew.id}`, crew)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }

  saveCastItems(cast: ICast) {
    return axiosInstance.put(`casts/${cast.id}`, cast)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }

  import(data: any, tableName: string, id: any) {
    return axiosInstance.post(`import/${tableName}?type=module&module_id=${id}`, {data})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }

  export(tableName: string, id: any) {
    return axiosInstance.get(`export/${tableName}?type=module&module_id=${id}`)
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }

  updateCrew(moduleId: number, seriesId: number, crewId: number, data: any) {
    return axiosInstance.put(`series/${this.id}/series/${seriesId}/crews/${crewId}`, {role: data})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }

  updateCast(moduleId: number, seriesId: number, castId: number, data: any) {
    return axiosInstance.put(`series/${this.id}/series/${seriesId}/casts/${castId}`, {role: data})
      .then((response) => response.data).catch((e) => {
        const log: any = {
          route: 'Series',
          message: e,
        };
        generateLog(log);
      });
  }
}
