
import {Component,  Prop, Watch} from 'vue-property-decorator';
import stores from '@/stores';
import Axios from 'axios';
import {mixin as Clickaway} from 'vue-clickaway';
import {SweetModal} from 'sweet-modal-vue';
import vPagination from '@/components/VuePlainPagination.vue';
import NevronSearchNoResults from '@/components/NevronSearchNoResults.vue';
import _ from 'lodash';
import {modulesMetaData} from '@/helpers/constants';
import Skeleton from '@/modules/ModulesSkeleton.vue';
import TableGeneric from '@/components/NevronTableGeneric.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';
import {translatedDisplayName} from '@/helpers/functions';
// Create a new Vue instance for the event bus
import Vue from 'vue';

export const EventBus = new Vue();

// Add the event bus to the Vue prototype
Vue.prototype.$bus = EventBus;
@Component({
  components: {
    SweetModal,
    vPagination,
    NevronSearchNoResults,
    Skeleton,
    TableGeneric,
  },
  mixins: [Clickaway],
})

export default class NevronTableModalGeneric extends Vue {
  @Prop({required: true})
  instance!: IModule;

  @Prop({default: null})
  metaKey!: string | null;

  @Prop({default: false})
  hasRemove!: boolean;


  @Prop({default: false})
  checkPopup!: boolean;

  @Prop({default: false})
  emitEdit!: boolean;

  @Prop({default: null})
  existingItems!: any;

  @Prop({default: true})
  checkEmit!: boolean;

  @Prop({default: true})
  firstColClickable!: boolean;


  createComponent: any = null;

  resetSelected = false;

  response: any = null;
  items: any = null;
  selectedItem: IItem | null = null;
  selectedArray: IItem[] = [];
  searchQuery: string = '';

  searchItems = _.debounce((res: any) => {
    this.fetchData();
  }, 400);

  get instanceMeta(): any {
    // @ts-ignore
    return modulesMetaData[this.instance.routeName];
  }

  get modalMeta(): any {
    if (this.metaKey && this.instanceMeta[this.metaKey]) {
      return this.instanceMeta[this.metaKey];
    }
    return this.instanceMeta;
  }

  mounted() {
    // @ts-ignore
    this.$bus.$on('loadAccessories', () => {
      this.fetchData();
    });
    if (this.instanceMeta.createComponent) {
      this.createComponent = () => import(`@/${this.instanceMeta.createComponent}.vue`);
    }
    this.$watch('$refs.modal.is_open', (oldState, newState) => {
      if (oldState === true && newState === false) {
        this.fetchData();
      }
    });
  }

  /**
   * Emits 'save' with an array containing their IDs
   */
  save() {
    if (!this.checkEmit) {
      const emitValue = (this.modalMeta.selectOne) ? this.selectedItem : this.selectedArray;
      const myArrayString = JSON.stringify(emitValue);
      localStorage.setItem('myArrayKey', myArrayString);
      localStorage.setItem('addRecipients', myArrayString);
      // @ts-ignore
      this.$bus.$emit('addRecipients');
      this.cancel();
    } else {
      const emitValue = (this.modalMeta.selectOne) ? this.selectedItem : this.selectedArray;
      this.$emit('save', emitValue);
      this.cancel();
    }
  }

  /**
   * Empties the selection array and closes the popup
   */
  @Watch('$route')
  cancel() {
    this.resetSelected = !this.resetSelected;
    // @ts-ignore
    this.$children[0].close();
  }

  @Watch('response.currentPage')
  fetchData(index = 1) {
    let ids = Array.isArray(this.existingItems) ? this.existingItems.map((el: any) => el.id) : [];
    ids = !Array.isArray(this.existingItems) && this.existingItems !== null ? this.existingItems : ids;
    return this.instance.fetchData!(index, this.searchQuery, 10, this.modalMeta.optionalParams)
      .then((response) => {
        this.response = response;
        if(response.data?.data !== undefined){
          this.items = response.data.data;
        }
        else{
          this.items = response.data;
        }

        this.items = Array.isArray(ids) ? this.items.filter((obj: any) => !ids.includes(obj.id)) :
          // @ts-ignore
          this.items.filter((obj: any) => ids.id !== obj.id);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  attachCreated(item: IItem) {
    const emitValue = (this.modalMeta.selectOne) ? item : [item];
    this.$emit('save', emitValue);
    this.cancel();
  }

  deleteItem(item: any) {
    this.$emit('deleteAccessory', item);
  }

  openItem(item: any) {
    this.$emit('editAccessory', item);
  }

}
